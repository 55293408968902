import { getRepeaterRows } from "./form_validator/extensions/collection/common/visit_repeater";
import { state } from "./state";
import {
    formatDateToYMD,
    formatISODate,
    isSafari,
    parseFormat,
    roundIntToQuarter,
    snakeToCamelCase,
} from "./utils";
import { prePopulateVisitDate } from "./validation/score/score_utils";

function removeDateStyleIfSafari() {
    document.getElementsByName("visit-date").forEach((input) => {
        if (isSafari()) {
            input.classList.remove("meye-date-picker");
        }
    });
}

/**
 * Init element repeater functionality.
 */
export function initRepeater() {
    removeDateStyleIfSafari();
    const addButtons = document.querySelectorAll(".repeater-add");

    addButtons.forEach((addButton) => {
        const count = state(2);
        setupRepeater(addButton, count);
    });
}

function setupRepeater(addButton, count) {
    const { repeater } = addButton.dataset;

    const repeaterWrapper = document.getElementById(repeater);
    const modalElement = document.querySelector(
        `[data-target=${repeaterWrapper.id}]`
    );

    addButton.addEventListener("click", () => {
        if (!repeaterWrapper) {
            throw Error("Repeater not found");
        }

        const trigger = () => {
            handleAddRepeaterItem(repeaterWrapper, count);
        };

        const addEvent = new CustomEvent("add-repeater-row", {
            cancelable: true,
            detail: { trigger },
        });
        addButton.dispatchEvent(addEvent);

        if (!addEvent.defaultPrevented) {
            trigger();
        }
    });

    handleRepeaterFocus(repeaterWrapper);
    validateDates(repeaterWrapper);
    modalElement.addEventListener("shown.bs.modal", (e) =>
        handleRemoveRepeaterItem(e, modalElement, repeaterWrapper, count)
    );
}

/**
 * Triggers when remove item modal is shown, removes repeater item entry
 * if user clicks on confirm action and clear listener on dismiss.
 * @param {Event} event
 */
function handleRemoveRepeaterItem(event, modalElement, repeaterWrapper, count) {
    const items = getRepeaterRows(repeaterWrapper.id, false);
    const baseItem = repeaterWrapper.querySelector(".repeater-item-base");
    const removeItem = modalElement.querySelector(".remove-repeater-item");
    const item = event.relatedTarget.closest(".repeater-item");
    const baseRows = getBaseRowsAttribute(repeaterWrapper);

    const handleClick = () => {
        const triggerRemove = () => {
            if (baseRows && items.length < baseRows) {
                replaceRepeaterItem(baseItem, item, count);
            } else {
                item.classList.add("exiting");
            }
        };

        const removeEvent = new CustomEvent("remove-repeater-row", {
            cancelable: true,
            detail: { trigger: triggerRemove },
        });

        removeItem.dispatchEvent(removeEvent);

        if (!removeEvent.defaultPrevented) {
            triggerRemove();
        }

        item.addEventListener("animationend", () => {
            const triggerRemoved = () => {
                item.remove();
            };

            const removedEvent = new CustomEvent("removed-repeater-row", {
                cancelable: true,
                detail: { trigger: triggerRemoved },
            });

            removeItem.dispatchEvent(removedEvent);

            if (!removedEvent.defaultPrevented) {
                triggerRemoved();
            }
        });
    };

    removeItem.addEventListener("click", handleClick);

    event.target.addEventListener("hidden.bs.modal", () => {
        removeItem.removeEventListener("click", handleClick);
    });
}

/**
 * @param {HTMLElement} repeaterWrapper
 */
function handleAddRepeaterItem(repeaterWrapper, count, refItem = undefined) {
    const [getCount, setCount] = count;

    const baseItems = repeaterWrapper.querySelectorAll(".repeater-item-base");
    const baseRows = getBaseRowsAttribute(repeaterWrapper);

    baseItems.forEach((baseItem) => {
        const item = refItem ? refItem : baseItem;

        const inputs = item.querySelectorAll("input,select");
        const newItem = createNewRepeaterItem(item, count);

        const removeRepeaterModal = newItem.querySelector(
            ".remove-repeater-modal"
        );
        removeRepeaterModal.classList.remove("invisible");

        newItem.classList.add("entering");

        if (baseRows && baseItem.id !== "visit-row") {
            baseItem.replaceWith(newItem);
        } else {
            insertNewItem(newItem, repeaterWrapper, baseItem);
        }

        const itemValues = generateRepeaterItemValues(newItem.id, inputs);
        insertRepeaterItemValues(newItem, itemValues);

        cleanBaseRepeaterItem(inputs, baseItem);
        baseItem.classList.remove("focused-inputs");
        setCount(getCount() + 1);
    });
}

/**
 * @param {HTMLElement} baseItem
 * @param {[Function, Function]} count
 */
function createNewRepeaterItem(baseItem, count) {
    const newItem = document.createElement("div");
    const baseId = baseItem.id.split("-").slice(0, 2).join("-");
    const classes = [...baseItem.classList, "row-display"].join(" ");
    const newClasses = classes.replace("repeater-item-base", "repeater-item");

    newItem.setAttribute("class", newClasses);

    newItem.id = recreateId(baseId, count);
    newItem.innerHTML = baseItem.innerHTML;

    const inputs = newItem.querySelectorAll("input,select");

    inputs.forEach((input) => {
        const error = newItem.querySelector(`#${input.name}-error-0`);

        if (error) {
            error.id = recreateId(error.id, count);
        }

        input.id = recreateId(input.id, count);
        input.classList.remove("fw-bold");
    });

    const errors = newItem.querySelectorAll(".error-red");

    errors.forEach((error) => {
        if (error.id) {
            error.id = recreateId(error.id, count);
        }
        error.dataset.validatorError = "";
    });

    return newItem;
}

function recreateId(id, count) {
    const regexEndsWithNumber = /\d+$/;
    const [getCount] = count;

    if (regexEndsWithNumber.test(id)) {
        return id.replace(regexEndsWithNumber, getCount());
    }
    return `${id}-${getCount()}`;
}

function cloneRepeaterItem(targetItem, count) {
    const clonedItem = targetItem.cloneNode(true);
    const visitDate = clonedItem.querySelector("input[name=visit-date]");
    if (visitDate) {
        visitDate.value = formatDateToYMD(new Date());
    }
    uniquifyItemIds(clonedItem, count);
    return clonedItem;
}

function replaceRepeaterItem(itemToClone, itemToReplace, count) {
    const clonedItem = cloneRepeaterItem(itemToClone, count);
    listenInputsFocus(clonedItem);
    itemToReplace.replaceWith(clonedItem);
}

function uniquifyItemIds(item, count) {
    item.id = recreateId(item.id, count);
    const elementsWithId = item.querySelectorAll("[id]");

    elementsWithId.forEach((element) => {
        if (element.id) {
            element.id = recreateId(element.id, count);
        }
    });
}

function generateRepeaterItemValues(id, inputs) {
    return [...inputs].reduce(
        (prev, curr) => {
            const [value, unit] = parseFormat(curr.dataset.format, curr.value);
            prev[curr.name] = value ? `${value} ${unit}`.trim() : "-";
            return prev;
        },
        { id }
    );
}

/**
 * Insert all row values in the new repeater item.
 * @param {HTMLElement} item
 * @param {object} values
 */
function insertRepeaterItemValues(item, values) {
    const inputs = item.querySelectorAll("input,select");

    inputs.forEach((input) => {
        if (input.type === "number") {
            input.setAttribute("type", "text");
        }

        input.readOnly = true;
        input.classList.add("input-readonly");
        input.value = values[input.name];
    });
}

/**
 * Reset input values to initial state and unfocus row.
 * @param {NodeListOf<HTMLInputElement>} inputs
 * @param {HTMLElement} baseItem
 */
function cleanBaseRepeaterItem(inputs, baseItem) {
    baseItem.blur();

    inputs.forEach((input) => {
        if (input.tagName === "SELECT") {
            input.selectedIndex = 0;
        } else if (input.id === "visit-date") {
            prePopulateVisitDate(input);
        } else {
            input.value = "";
        }
    });
}

function insertNewItem(newItem, repeaterWrapper, baseItem) {
    const { insertAt } = repeaterWrapper?.dataset ?? "bottom";

    if (insertAt === "top") {
        const [first] = [...repeaterWrapper.children];
        repeaterWrapper.insertBefore(newItem, first);
    } else {
        repeaterWrapper.insertBefore(newItem, baseItem);
    }
}

/**
 * Handle focus repeater items.
 * @param {HTMLElement} repeaterWrapper
 */
function handleRepeaterFocus(repeaterWrapper) {
    const focusItems = repeaterWrapper.querySelectorAll(".focus-item");

    focusItems.forEach((focusItem) => {
        listenInputsFocus(focusItem);
    });
}

function listenInputsFocus(item) {
    const inputs = item.querySelectorAll("input,select");

    const addFocusedInputsClass = () => {
        item.classList.add("focused-inputs");
    };

    inputs.forEach((input) => {
        input.addEventListener("input", addFocusedInputsClass);
        input.addEventListener("focus", addFocusedInputsClass);

        input.addEventListener("focusout", () => {
            if (!input.value) {
                return;
            }

            const [value] = parseFormat(input.dataset.format, input.value);

            if (input.getAttribute("step") === "0.25") {
                input.value = roundIntToQuarter(value).toString();
            } else if (
                Number.isInteger(parseFloat(input.getAttribute("step")))
            ) {
                input.value = parseInt(value);
            } else {
                input.value = value;
            }
        });
    });
}

function getBaseRowsAttribute(repeaterWrapper) {
    const baseRows = repeaterWrapper?.dataset?.baseRows;

    if (baseRows) {
        return Number(baseRows);
    }
}

/**
 *
 * @param {HTMLElement} repeaterWrapper
 */
function validateDates(repeaterWrapper) {
    const dateInputs = repeaterWrapper.querySelectorAll("input[type=date]");
    const dobInputs = document.querySelectorAll(".date-dob");

    window.addEventListener("valid-dob", () => {
        const dateObject = [...dobInputs].reduce((prev, curr) => {
            prev[snakeToCamelCase(curr.name)] = curr.value;
            return prev;
        }, {});

        const { dobDd, dobMm, dobYyyy } = dateObject;
        const dobDate = new Date(dobYyyy, dobMm - 1, dobDd);

        const minDate = new Date(
            dobDate.setFullYear(dobDate.getFullYear() + 6)
        );

        dateInputs.forEach((input) => {
            input.min = formatISODate(minDate);
        });
    });
}
