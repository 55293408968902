import {
    buildForgotPasswordSchema,
    buildRegisterSchema,
    buildResetPasswordSchema,
} from "./user";
import {
    buildGuideSchema,
    buildScoreRowScheme,
    buildScoreTreatmentScheme,
} from "./guide";
import {
    buildNewScoreSchema,
    buildUpdateScoreSchema,
    buildUseScoreSchema,
} from "./score";

export const SCHEMAS = Object.freeze({
    registerUser: buildRegisterSchema,
    forgotPassword: buildForgotPasswordSchema,
    resetPassword: buildResetPasswordSchema,
    guideForm: buildGuideSchema,
    scoreNewForm: buildNewScoreSchema,
    scoreUpdateForm: buildUpdateScoreSchema,
    scoreUseForm: buildUseScoreSchema,
    scoreRowForm: buildScoreRowScheme,
    scoreTreatmentForm: buildScoreTreatmentScheme,
});
