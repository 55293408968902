/**
 * JS : Date Of Birth
 *
 * Product by Ocumetra
 *
 */
import { initAutotab } from "./autotab";
import { removeError } from "./error_validation_system";
import { FormValidator } from "./form_validator/form_validator.js";
import { ExtraError } from "./form_validator/extensions/constants.js";
import { isSafari } from "./utils.js";

let dateFormat = "dd-mm-yyyy"; // Default value
let objDateFormat = {};

/**
 * Init the Date of Birth (DOB) according to the date-format
 */
function initDOB() {
    const inputDOBs = document.getElementsByClassName("date-dob");
    if (!inputDOBs?.length) return;

    dateFormat = "dd-mm-yyyy";
    objDateFormat = splitDateFormat();

    for (const inputDOB of inputDOBs) {
        inputDOB.addEventListener("blur", () => {
            completeInputDOB(inputDOB);
            removeInvalidDOBError();
            onBlurDOB();
        });
        inputDOB.addEventListener("keyup", (e) => {
            removeInvalidDOBError();
            checkDateKeyUp(e, inputDOB);
        });
    }
    initAutotab(inputDOBs, [" ", ".", "-", "/"]);
}

/**
 * Remove custom dob error generated in the backend.
 */
function removeInvalidDOBError() {
    const invalidDOB = document.querySelector(".invalid-dob");

    if (!invalidDOB) {
        return;
    }

    invalidDOB.classList.add("d-none");
}

/**
 * Init and display all the date
 */
export function initDisplayDate() {
    const dates = document.getElementsByClassName("display-date");
    for (const date of dates) {
        // If no date is set, get the current date, otherwise get the formatted date
        if (date.textContent === "") {
            date.textContent = getDate();
        } else if (date.textContent.includes("-")) {
            const dateParts = date.textContent.split("-");
            // month keys are 0-based

            // reverse if date format is yyyy-mm-dd
            if (dateParts[0].length === 4) {
                dateParts.reverse();
            }

            const dateObject = new Date(
                +dateParts[2],
                dateParts[1] - 1,
                +dateParts[0]
            );
            date.textContent = getDate(dateObject);
        }
    }
}

/**
 * Split the date format to get an ordered array (exemple : ['DD','MM','YYYY']) and its separator
 * @returns JS object that contains split array and separator
 */
function splitDateFormat() {
    const tabSeparator = ["-", "."];
    let split;
    // Sets the split (array) and separator
    for (const sep of tabSeparator) {
        split = dateFormat.split(sep);
        if (split.length > 1) {
            return { split: split, separator: sep };
        }
    }
}

/**
 * On Blur : Complete a DOB input according to the type ('DD', 'MM' or 'YYYY')
 * Exemple : When the 'DD' input is filled in with '1', it is converted to '01'
 * @param {*} input
 */
function completeInputDOB(input) {
    const typeDate = input.id;
    let convertDate = "";
    input.value = input.value === "0" ? 1 : input.value;
    if (typeDate === "dob-yyyy") {
        while (input.value.length < 3 && input.value !== "")
            input.value = 0 + input.value;
        convertDate = input.value.length == 3 ? 2 + input.value : input.value;
    } else {
        convertDate = input.value.length == 1 ? 0 + input.value : input.value;
    }
    input.value = convertDate;
}

/**
 * On Blur : Call the showAge function when the whole date is completed.
 */
function onBlurDOB() {
    if (getInputDOBStr().length === dateFormat.length) {
        showAge();
    }
}

/**
 * Get the DOB to a string format
 * @returns
 */
function getInputDOBStr() {
    const [date0, date1, date2] = objDateFormat.split;
    const valueDOB0 = document.getElementById("dob-" + date0).value;
    const valueDOB1 = document.getElementById("dob-" + date1).value;
    const valueDOB2 = document.getElementById("dob-" + date2).value;
    // Create the DOB to a string format
    let strDOB = "";
    strDOB += valueDOB2;
    strDOB += objDateFormat.separator;
    strDOB += valueDOB1 === "" ? 0 : valueDOB1;
    strDOB += objDateFormat.separator;
    strDOB += valueDOB0 === "" ? 0 : valueDOB0;
    return strDOB;
}

/**
 * On Keyup : Check if the date is completed and shows the age, otherwise remove/hide the error
 * @param {*} event - event from Keyup
 * @param {*} input
 */
function checkDateKeyUp(event, input) {
    const containerDOB = document.querySelector(".container-dob");

    const key = event.key || event.code;
    // Calculate age only when the date is completed
    if (getInputDOBStr().length === dateFormat.length) {
        if (containerDOB) {
            containerDOB.style.visibility = "visible";
        }
        showAge();
    } else {
        if (containerDOB) {
            containerDOB.style.visibility = "hidden";
        }
        document.getElementById("current-age").style.display = "none";
        document.getElementById("error-range-dob").style.display = "none";
        document.getElementById("container-dob").removeAttribute("required");
        removeError($("#container-dob"));
    }
    // Remove the error on the DOB input when the key is different of Enter
    if (key !== "Enter") {
        removeError(input);
    }
}

/**
 * Shows the age when the age/date are validated, otherwise displays an error
 */
function showAge() {
    const dobDate = document.getElementById("dob-date");
    const dateIsValid = dobDate.value !== "";
    const errorInputDOB = document.getElementById("dob-entry-error");
    const errorRangeDOB = document.getElementById("error-range-dob");
    const containerDOB = document.getElementById("container-dob");
    const currentAge = document.getElementById("current-age");
    const currentAgeMonths = document.getElementById("current-age-months");
    const hasContainerDOBclass =
        containerDOB.classList.contains("container-dob");
    const shouldValidate = !errorRangeDOB.classList.contains("no-validate");
    const form = FormValidator.getInstance("submit-guide-form");

    errorInputDOB.style.visibility = "visible";

    function failDate(message) {
        currentAge.style.display = "none";
        containerDOB.setAttribute("required", "required");
        errorRangeDOB.style.display = "inline-block";
        errorRangeDOB.innerText = message;
        errorInputDOB.style.visibility = "hidden";
        if (form) {
            form.addExtraError(ExtraError.AGE_RANGE);
        }
    }

    // Display/hide dob container for old form and meye gauge
    if (!dateIsValid && shouldValidate) {
        failDate("Invalid date");
        errorInputDOB.style.visibility = "hidden";
    } else {
        errorInputDOB.style.visibility = "visible";
    }

    // Display/hide dob container for new meye guide
    if (!shouldValidate && hasContainerDOBclass) {
        containerDOB.style.visibility = !dateIsValid ? "hidden" : "visible";
    }

    let ageYears;
    let ageMonths;
    if (dateIsValid) {
        const visitDateInput = document.querySelector("input[name=visit_date]");

        const visitDate = visitDateInput
            ? new Date(visitDateInput.value)
            : new Date();

        const convertDate = new Date(dobDate.value);

        ageYears = visitDate.getFullYear() - convertDate.getFullYear();
        ageMonths = visitDate.getMonth() - convertDate.getMonth();

        if (ageMonths < 0) {
            ageMonths = 12 + ageMonths;
            ageYears = ageYears - 1;
        }
    } else {
        return;
    }

    if (dateIsValid && ageYears < 19 && ageYears >= 6) {
        document.getElementById("age-years").innerText = ageYears;
        currentAge.style.display = "inline-block";

        window.dispatchEvent(
            new CustomEvent("valid-dob", { detail: { age: ageYears } })
        );

        if (ageMonths !== 0) {
            document.getElementById("age-months").innerText = ageMonths;
            currentAgeMonths.style.display = "inline-block";
        } else {
            currentAgeMonths.style.display = "none";
        }

        errorRangeDOB.style.display = "none";
        containerDOB.removeAttribute("required");
        removeError($("#container-dob"));
        disableSubmitButton(false);
        if (form) {
            form.removeExtraError(ExtraError.AGE_RANGE);
        }
    } else if (ageYears >= 19 || ageYears < 6) {
        disableSubmitButton();
        if (dateIsValid) {
            const convertDate = new Date(dobDate.value);
            if (convertDate.getFullYear() > 1000)
                failDate("Insert a valid age between 6-18");
        }
    }
}

export function disableSubmitButton(disable = true) {
    const scoreSubmitButton = document.getElementById(
        "submit-new-score-form-submitter"
    );
    const guideSubmitButton = document.getElementById(
        "submit-guide-form-submitter"
    );

    if (scoreSubmitButton) {
        scoreSubmitButton.disabled = disable;
    }

    if (guideSubmitButton) {
        guideSubmitButton.disabled = disable;
    }
}

/**
 * Get the date (today) according to the format/dateFormat
 * @param {*} format
 * @returns the current date
 */
export function getDate(date = "") {
    date = date ? date : Date.now();
    return new Intl.DateTimeFormat(["default", "en-GB"], {
        year: "numeric",
        month: "short",
        day: "2-digit",
    }).format(date);
}

/**
 * Check if the DOB is valid or not.
 * @param {string} value
 * @returns {boolean} true/false
 */
export function validDate(value) {
    return !isNaN(Date.parse(new Date(value)));
}

/**
 * Check if the date instance is valid or not.
 * @param {Date} date
 * @returns {boolean} true/false
 */
export function validDateInstance(date) {
    return !isNaN(Date.parse(date));
}

/**
 * Recalculate age everytime visit date changes.
 */
function handleDateInputs() {
    const visitDateInput = document.querySelector("input[name=visit_date]");
    const dobInput = document.querySelector("input[name=dob_date]");
    if (visitDateInput) {
        visitDateInput.addEventListener("input", () => {
            showAge();
        });
    }
    if (dobInput) {
        dobInput.addEventListener("input", () => {
            showAge();
        });

        if (isSafari()) {
            implementDOBHandlingOnSafari();
        }
    }
}

function isDateValid(month, year, day) {
    if (month < 1 || month > 12) {
        return false;
    }

    let daysInMonth = 31;
    if (month === 4 || month === 6 || month === 9 || month === 11) {
        daysInMonth = 30;
    } else if (month === 2) {
        if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            daysInMonth = 29;
        } else {
            daysInMonth = 28;
        }
    }
    return day > 0 && day <= daysInMonth;
}

function getDateOfBirthFromParts(dateParts, inputParts) {
    let day = 0;
    let month = 0;
    let year = 0;
    dateParts.forEach((part, index) => {
        if (part.split("D").length > 1) {
            day = parseInt(inputParts[index]);
        } else if (part.split("M").length > 1) {
            month = parseInt(inputParts[index]);
        } else if (part.split("Y").length > 1) {
            year = parseInt(inputParts[index]);
        }
    });
    const validDate = isDateValid(month, year, day);
    const dateOfBirth = new Date(year, month - 1, day);
    return { dateOfBirth, validDate };
}

function truncatePartsWhenEditingCompleteDate(
    inputParts,
    dateParts,
    patched,
    separator
) {
    const separatorCount = countSeparatorOccurrences(patched, separator);
    // Needed to correct numbers too big when editing a completed field
    if (separatorCount > 0) {
        if (inputParts[0].length > dateParts[0].length) {
            patched =
                inputParts[0].slice(0, dateParts[0].length) +
                separator +
                inputParts[1] +
                separator +
                inputParts[2];
        }
    }
    if (separatorCount > 1) {
        if (inputParts[1].length > dateParts[1].length) {
            patched =
                inputParts[0] +
                separator +
                inputParts[1].slice(0, dateParts[1].length) +
                separator +
                inputParts[2];
        }
    }
    return patched;
}

function formatDateText(text, separator, dateParts) {
    const regex = new RegExp(`[^0-9\\${separator}]`, "g");
    let patched = text.replace(regex, "");
    patched = patched.replace(separator + separator, separator);
    let completeDOB = false;
    const separatorCount = countSeparatorOccurrences(patched, separator);
    const inputParts = patched.split(separator);
    const lastCharacter = patched.slice(-1);

    patched = truncatePartsWhenEditingCompleteDate(
        inputParts,
        dateParts,
        patched,
        separator
    );

    // Now complete slashes if needed, truncate fields too long when new and mark complete if so
    if (separatorCount === 0) {
        if (patched.length > dateParts[0].length) {
            patched = patched.slice(0, -1) + separator + lastCharacter;
        }
    } else if (separatorCount === 1) {
        if (inputParts[1].length > dateParts[1].length) {
            patched = patched.slice(0, -1) + separator + lastCharacter;
        }
    } else if (separatorCount === 2) {
        if (inputParts[2].length >= dateParts[2].length) {
            if (inputParts[2].length > dateParts[2].length) {
                patched = patched.slice(0, -1);
            }
            completeDOB = true;
        }
    }
    return { patched, completeDOB };
}

// Need to get around Safari displaying the current date if DOB is empty
function implementDOBHandlingOnSafari() {
    const dobInput = document.querySelector("input[name=dob_date]");
    const dateFormat = getDateFormat();
    const separator = getDateFormatSeperator(dateFormat);
    const dateParts = dateFormat.split(separator);

    const visitDateInput = document.querySelector("input[id=fake-dob-date]");
    if (visitDateInput === null) {
        const textDateInput = document.createElement("input");
        textDateInput.id = "fake-dob-date";
        dobInput.parentNode.appendChild(textDateInput);
        dobInput.style.display = "none";
        dobInput.inputMode = "numeric";
        textDateInput.type = "text";
        textDateInput.placeholder = dateFormat.toLowerCase();
        textDateInput.addEventListener("input", () => {
            let inputValid = false;
            const { patched, completeDOB } = formatDateText(
                textDateInput.value,
                separator,
                dateParts
            );

            if (completeDOB === true) {
                const inputParts = patched.split(separator);
                const { dateOfBirth, validDate } = getDateOfBirthFromParts(
                    dateParts,
                    inputParts
                );
                if (validDate === true) {
                    const formattedParts = dateOfBirth.toISOString().split("T");
                    if (formattedParts.length > 1) {
                        dobInput.value = formattedParts[0];
                        console.log("Dispatch");
                        dobInput.dispatchEvent(new Event("input"));
                        inputValid = true;
                    }
                }
            }
            textDateInput.value = patched;
            if (inputValid === false) {
                dobInput.value = "";
                if (completeDOB === true) {
                    console.log("Dispatch");
                    dobInput.dispatchEvent(new Event("input"));
                }
            }
            showAge();
        });
    }
}

function getDateFormat() {
    const testDate = new Date(2013, 11, 21);
    let localeDate = testDate.toLocaleDateString();
    localeDate = localeDate.replace("2013", "YYYY");
    localeDate = localeDate.replace("12", "MM");
    localeDate = localeDate.replace("21", "DD");
    // Fallback in case we get a two digit year
    localeDate = localeDate.replace("13", "YYYY");
    return localeDate;
}

function getDateFormatSeperator(dateFormat) {
    const chars = dateFormat.replace(/[a-zA-Z0-9]/g, "");
    if (chars.length > 1) {
        return chars[0];
    }
    return "-";
}

function countSeparatorOccurrences(str, separator) {
    // Split the string by the separator and subtract 1 from the length of the resulting array
    return str.split(separator).length - 1;
}

// Launch the library to the load.
window.addEventListener("load", () => {
    handleDateInputs();
});
