/**
 * JS : Vision Simulator
 *
 * Product by Ocumetra
 *
 */

/**
 * Init vision simulator
 */
export function initVisionSimulator() {
    [...document.getElementsByClassName("vision-sim-container")]
        .filter((container) => !container.querySelector(".div-background"))
        .forEach((container) => {
            initVisionSimContainer(container);
        });

    showImageContainers();
    setEyeImages();
    initLoaderModal();

    ["OS", "OD"].forEach((eye) => {
        validateCylinderAxis(eye);
    });
}

/**
 * Init the vision simulator container
 * @param {*} container
 */
function initVisionSimContainer(container) {
    let clicked = false;
    // init the handle
    const handle = createHandle(container);

    // init the divs that contain the images and the spans
    const [divBackground, divCover] = createElementsForContainer(
        container,
        handle
    );
    container.appendChild(divBackground);
    container.appendChild(divCover);

    container.appendChild(handle);
    // position the handle in the middle (50%)
    changePosSlider(50);
    // add event listeners
    container.addEventListener("mousedown", startSlide);
    container.addEventListener("touchstart", startSlide);
    window.addEventListener("mouseup", endSlide);
    window.addEventListener("touchend", endSlide);

    /**
     * Start the slide
     * @param {*} event - mousedown/touchstart event
     */
    function startSlide(event) {
        // prevent any other actions that may occur when moving over the image
        event.preventDefault();
        // the slider is now clicked and ready to move
        clicked = true;
        // move the slider for the first click
        moveSlider(event);
        // execute a function when the slider is moved
        window.addEventListener("mousemove", moveHandle);
        window.addEventListener("touchmove", moveHandle);
    }

    /**
     * finish the slide
     */
    function endSlide() {
        // the slider is no longer clicked
        clicked = false;
    }

    /**
     * Move the handle (slider) and stop it when is no longer clicked
     * @param {*} event - mousemove/touchmove event
     */
    function moveHandle(event) {
        // if the slider is no longer clicked, exit this function
        if (!clicked) return false;
        // move the slider when it's moving
        moveSlider(event);
    }

    /**
     * Move the handle (slider)
     * @param {*} event - mousemove/touchmove event
     */
    function moveSlider(event) {
        // get the cursor's x position
        const posX = getPercentCursorPosX(event);
        // execute a function that change the position of the silder
        changePosSlider(posX);
    }

    /**
     * Get the position of the cursor in percentage
     * @param {*} event - mousemove/touchmove event
     */
    function getPercentCursorPosX(event) {
        event = event.changedTouches ? event.changedTouches[0] : event;
        // get the x positions of the image
        const imgPos = divCover.getBoundingClientRect();
        // calculate the cursor's x coordinate, relative to the image
        const cursorPosX = event.pageX - imgPos.left - window.scrollX;
        // calculate the percentage of the cursor's x coordinate
        const percentCursorPosX = (cursorPosX / container.offsetWidth) * 100;
        // prevent the slider from being positioned outside the image
        if (percentCursorPosX < 0) return 0;
        if (percentCursorPosX > 100) return 100;
        return percentCursorPosX;
    }

    /**
     * Change the position of the slider
     * @param {*} posX
     */
    function changePosSlider(posX) {
        divCover.style.clipPath = "inset(0 0 0 " + posX + "%)";
        handle.style.left = posX + "%";
    }
}

/**
 * Show vision simulator images.
 */
function showImageContainers() {
    const imageContainers = document.querySelectorAll(".img-vs-container");

    if (!imageContainers?.length) {
        return;
    }

    imageContainers.forEach((imageContainer) => {
        imageContainer.style.display = "flex";
    });
}

/**
 * Create the elements (images and spans) for the container
 * @param {HTMLElement} container
 * @param {HTMLElement} handle
 */
function createElementsForContainer(container, handle) {
    const [imgBackground, imgCover, ...rest] = container.children;
    // Create divs
    const divBackground = document.createElement("div");
    const divCover = document.createElement("div");
    // Add class on the divs
    divBackground.className = "div-background";
    divCover.className = "div-cover";
    // Create and add span for divBackground

    if (container.classList.contains("handle-tooltip")) {
        createTooltipInHandle(handle, imgBackground, imgCover);
    } else {
        createTooltipInContainer(
            imgBackground,
            imgCover,
            divBackground,
            divCover
        );
    }
    // Add images to the 2 divs
    divBackground.appendChild(imgBackground);
    divCover.appendChild(imgCover);

    if (rest.length > 0) {
        rest.forEach((element) => {
            divCover.appendChild(element);
        });
    }

    // Remove all elements from the container
    container.innerHTML = "";
    return [divBackground, divCover];
}

/**
 * Create the handle (slider)
 * @param {HTMLElement} container
 */
function createHandle(container) {
    // Create the elements for the handle
    const handle = document.createElement("div");
    const controller = document.createElement("div");
    const leftArrow = document.createElement("div");
    const rightArrow = document.createElement("div");

    // Add class on the elements
    handle.className = "handle";
    controller.className = "controller";
    leftArrow.className = "control-arrow left";
    rightArrow.className = "control-arrow right";

    if (container.classList.contains("handle-icon")) {
        const controllerIcon = document.createElement("div");

        controllerIcon.className = "controller-icon";
        controller.appendChild(controllerIcon);
    }

    // Add elements together
    controller.appendChild(leftArrow);
    controller.appendChild(rightArrow);
    handle.appendChild(controller);

    return handle;
}

/**
 * Create tooltip for simulator's container element.
 * @param {HTMLImageElement} imgBackground
 * @param {HTMLImageElement} imgCover
 * @param {HTMLElement} divBackground
 * @param {HTMLElement} divCover
 */
function createTooltipInContainer(
    imgBackground,
    imgCover,
    divBackground,
    divCover
) {
    if (imgBackground.alt) {
        const spanBackground = document.createElement("span");
        spanBackground.innerHTML = imgBackground.alt;
        divBackground.appendChild(spanBackground);
    }

    if (imgCover.alt) {
        const spanCover = document.createElement("span");
        spanCover.innerHTML = imgCover.alt;
        divCover.appendChild(spanCover);
    }
}

/**
 * Create tooltip for simulator's handle element.
 * @param {HTMLElement} handle
 * @param {HTMLImageElement} imgBackground
 * @param {HTMLImageElement} imgCover
 *
 */
function createTooltipInHandle(handle, imgBackground, imgCover) {
    createTooltipElements(imgBackground, imgCover).forEach((element) => {
        handle.appendChild(element);
    });
}

/**
 * Create tooltip's elements for simulator's handle element.
 * @param {HTMLImageElement} imgBackground
 * @param {HTMLImageElement} imgCover
 *
 */
function createTooltipElements(imgBackground, imgCover) {
    const backgroundTooltip = document.createElement("div");
    const coverTooltip = document.createElement("div");

    backgroundTooltip.className = "simulator-tooltip";
    coverTooltip.className = "simulator-tooltip";

    backgroundTooltip.innerText = imgBackground.alt;
    coverTooltip.innerText = imgCover.alt;

    return [backgroundTooltip, coverTooltip];
}

/**
 * Toggle vision simulator eyes data.
 */
function setEyeImages() {
    const eyeInput = document.querySelector("#vision-simulator-eye");
    const leftImages = document.querySelectorAll(
        ".vision-simulator-result-image-left"
    );
    const rightImages = document.querySelectorAll(
        ".vision-simulator-result-image-right"
    );
    const staticImageTexts = document.querySelectorAll(".static-image-texts");
    const myopiaLevels = document.querySelectorAll(".myopia-level");

    if (!eyeInput) return;

    eyeInput.addEventListener("input", () => {
        [...leftImages, ...rightImages].forEach((image) => {
            image.classList.toggle("hide-image");
        });

        staticImageTexts.forEach((staticImageText) => {
            staticImageText.classList.toggle("d-flex");
            staticImageText.classList.toggle("d-none");
        });

        myopiaLevels.forEach((myopiaLevel) => {
            myopiaLevel.classList.toggle("d-none");
        });
    });
}

/**
 *  Set pointer events behaviour on document.
 * @param {string} value
 */
function setDocumentPointerEvents(value = "auto") {
    document.documentElement.style.pointerEvents = value;
}

/**
 *  Show loader modal when simulator form is submitted.
 * @param {HTMLElement} loaderModal
 * @param {HTMLFormElement} form
 */
function handleSubmitForm(loaderModal, form) {
    loaderModal.classList.add("show-loader");
    setDocumentPointerEvents("none");
    form.submit();
}

/**
 *  Show loader modal when simulator form is submitted.
 * @param {HTMLElement} loaderModal
 */
function handleClickSampleLink(loaderModal) {
    loaderModal.classList.add("show-loader");
    setDocumentPointerEvents("none");
}

/**
 *  Initialize loader modal for vision simulator form.
 */
function initLoaderModal() {
    const loaderModal = document.querySelector(".loader-modal");
    const form = document.querySelector("#vision-simulator-form");
    const sampleLink = document.querySelector("#sample-vision-simulator-link");

    if (!loaderModal) return;

    form.addEventListener("submit", (event) => {
        event.preventDefault();
        handleSubmitForm(loaderModal, form);
    });

    sampleLink.addEventListener("click", () => {
        handleClickSampleLink(loaderModal);
    });

    // Disable loader modal if something went wrong when loading the page.
    window.addEventListener("beforeunload", function () {
        setTimeout(function () {
            setDocumentPointerEvents("auto");
            loaderModal.classList.remove("show-loader");
        }, 10 * 1000);
    });
}

/**
 *  Handle input cylinder/axis.
 * @param {HTMLInputElement} input
 * @param {HTMLInputElement} dependentInput
 */
function onInputCylinderAxis(input, dependentInput) {
    if (input.value) {
        dependentInput.setAttribute("required", "required");
    }
}

/**
 *  Handle input cylinder/axis.
 * @param {HTMLInputElement} input
 * @param {HTMLInputElement} dependentInput
 * @param {HTMLInputElement} range
 */
function onInputRangeCylinderAxis(input, dependentInput, range) {
    input.value = parseCylinderAxisValue(input.id, range.value);

    if (input.value) {
        dependentInput.setAttribute("required", "required");
    }
}
/**
 * Parses input value according to its id.
 * @param {string} id
 * @param {number} value
 * @returns {number}
 */
export function parseCylinderAxisValue(id, value) {
    return id?.includes("axis")
        ? parseInt(value)
        : parseFloat(value).toFixed(2);
}

/**
 *  Validation listeners for cylinder and axis.
 * @param {string} eye
 */
function validateCylinderAxis(eye) {
    const cylinderInput = document.querySelector(`#cylinder${eye}-input`);
    const cylinderRange = document.querySelector(`#cylinder${eye}-range`);
    const axisInput = document.querySelector(`#axis${eye}-input`);
    const axisRange = document.querySelector(`#axis${eye}-range`);
    const rangeEvents = ["click", "input"];

    if (!(cylinderInput && cylinderRange && axisInput && axisRange)) return;

    cylinderInput.addEventListener("input", () => {
        onInputCylinderAxis(cylinderInput, axisInput);
    });

    rangeEvents.forEach((eventType) => {
        cylinderRange.addEventListener(eventType, () => {
            onInputRangeCylinderAxis(cylinderInput, axisInput, cylinderRange);
        });
    });

    axisInput.addEventListener("input", () => {
        onInputCylinderAxis(axisInput, cylinderInput);
    });

    rangeEvents.forEach((eventType) => {
        axisRange.addEventListener(eventType, () => {
            onInputRangeCylinderAxis(axisInput, cylinderInput, axisRange);
        });
    });
}

export function animateVisionSimulations() {
    const animatedSimulations = document.querySelectorAll(
        ".animate-simulation"
    );

    if (!animatedSimulations?.length) {
        return;
    }

    animatedSimulations.forEach((animatedSimulation) => {
        animatedSimulation.classList.add("active");
    });
}

export function postMessageSimulatorCtaButton() {
    const simulatorButton = document.querySelector("#simulator-cta-button");

    if (!simulatorButton) return;

    simulatorButton.addEventListener("click", () => {
        window.parent.postMessage("cta-simulator-clicked", "*"); // NOSONAR
    });
}
