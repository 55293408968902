import { z } from "zod";
import {
    emailSchema,
    nullishStringSchema,
    passwordSchema,
    stringSchema,
    truthyBooleanSchema,
} from "./common";

export const buildRegisterSchema = (errorObject) => {
    return z
        .object({
            firstName: stringSchema(errorObject?.firstName),
            lastName: stringSchema(errorObject?.lastName),
            occupation: nullishStringSchema(errorObject?.occupation),
            organization: stringSchema(errorObject?.organization),
            postalCode: nullishStringSchema(errorObject?.postalCode),
            city: stringSchema(errorObject?.city),
            country: stringSchema(errorObject?.country),
            email: emailSchema(errorObject?.email),
            confirmEmail: emailSchema(errorObject?.confirmEmail),
            password: passwordSchema(errorObject?.password),
            confirmPassword: passwordSchema(errorObject?.confirmPassword),
            termsAndConditions: truthyBooleanSchema(
                errorObject?.termsAndConditions
            ),
        })
        .refine(
            ({ password, confirmPassword }) => password === confirmPassword,
            {
                message: errorObject?.confirmPassword?.match,
                path: ["confirmPassword"],
            }
        )
        .refine(({ email, confirmEmail }) => email === confirmEmail, {
            message: errorObject?.confirmEmail?.match,
            path: ["confirmEmail"],
        });
};

export const buildForgotPasswordSchema = (errorObject) => {
    return z.object({
        email: emailSchema(errorObject?.email),
    });
};

export const buildResetPasswordSchema = (errorObject) => {
    return z.object({
        password: passwordSchema(errorObject?.password),
        confirmPassword: passwordSchema(errorObject?.confirmPassword),
    });
};
