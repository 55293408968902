const SUPPORTED_BROWSERS_BOWSER_CONFIG = {
    chrome: ">=129",
    edge: ">=128",
    safari: ">=18",
};

const MIN_SUPPORTED_BROWSER_VERSIONS = {
    chrome: 129,
    edge: 128,
    safari: 18,
};

export { SUPPORTED_BROWSERS_BOWSER_CONFIG, MIN_SUPPORTED_BROWSER_VERSIONS };
