import Bowser from "bowser";
import { SUPPORTED_BROWSERS_BOWSER_CONFIG } from "./constants";

export const browser = Bowser.getParser(window.navigator.userAgent);
/**
 * Swap between two classes.
 * @param {HTMLElement} element
 * @param {string} newClass
 * @param {string} oldClass
 * @param {boolean} condition
 */
export function swapClasses(element, newClass, oldClass) {
    element.classList.add(newClass);
    element.classList.remove(oldClass);
}

/**
 * Round number to the nearest .25
 */
export function roundIntToQuarter(number) {
    return (Math.round(number * 4) / 4).toFixed(2);
}

/**
 * Check if current browser is Safari
 * @returns {boolean}
 */
export function isSafari() {
    const { userAgent } = navigator;

    return (
        userAgent.indexOf("Safari") > -1 &&
        userAgent.indexOf("Chrome") < 0 &&
        userAgent.indexOf("Firefox") < 0 &&
        userAgent.indexOf("Chrome") < 0
    );
}

/**
 * Swap classes according to the given condition.
 * @param {boolean} condition
 * @param {HTMLElement} element
 * @param {string} class1
 * @param {string} class2
 */
export function swapClassesIf(condition, element, class1, class2) {
    if (condition) {
        swapClasses(element, class1, class2);
    } else {
        swapClasses(element, class2, class1);
    }
}

/**
 * Restrict a value between a minimum and maximum range.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns number
 */
export function valueInRange(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * Run element callback once if initCondition is true and add listener.
 *
 * @param {HTMLElement} element
 * @param {boolean} initCondition
 * @param {string} eventName
 * @param {(HTMLElement) => any} callback
 */
export function initThenListen(element, initCondition, eventName, callback) {
    if (initCondition) {
        callback(element);
    }

    element.addEventListener(eventName, () => callback(element));
}

/**
 *
 * @param {HTMLInputElement} input
 * @param {any} value
 */
export function setInputValue(input, value) {
    input.value = value;
    input.setAttribute("value", value);
}

/**
 * Animate numeric progression.
 * @param {string} idDiv
 * @param {number} initVal
 * @param {number} lastVal
 * @param {number} duration
 */
export function animateNumericProgression(idDiv, initVal, lastVal, duration) {
    const obj = document.getElementById(idDiv);
    let calculatedValue;
    let startTime = null;

    function step(currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }
        // calculate the value to be used in calculating the number to be displayed
        const progress = Math.min((currentTime - startTime) / duration, 1);

        // calculate what to be displayed using the value above
        calculatedValue = Math.floor(progress * (lastVal - initVal) + initVal);
        obj.innerText = calculatedValue.toLocaleString();

        // checking to make sure the counter does not exceed the last value(lastVal)
        if (progress < 1) {
            window.requestAnimationFrame(step);
        } else {
            window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
    }

    // start animating
    window.requestAnimationFrame(step);
}

/** Uppercase first letter of string;
 * @param {string} str
 * @returns {string}
 */
export function upperCaseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Lowercase first letter of string;
 * @param {string} str
 * @returns {string}
 */
export function lowerCaseFirstLetter(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Remove substring from string;
 * @param {string} str
 * @returns {string}
 */
export function cut(str, strToRemove) {
    return str.replace(strToRemove, "");
}

/**
 * Reduce Object.entries() with the given condition.
 * @param {object} obj
 * @param {(key: string, value: any) => boolean} conditionFunction
 * @returns {object}
 */
export function reduceEntries(obj, conditionFunction) {
    return Object.entries(obj).reduce((prev, [key, value]) => {
        if (conditionFunction(key, value)) {
            prev[key] = value;
        }
        return prev;
    }, {});
}

/**
 *
 * @param {HTMLElement} container
 * @returns {NodeListOf<HTMLInputElement>}
 */
export function getInputs(container) {
    return container.querySelectorAll("input,select");
}

/**
 * Check if the viewport width is within the deskop breakpoint.
 * @returns {boolean}
 */
export function isDesktopBreakpoint() {
    return document.documentElement.clientWidth >= 1024;
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function kebabToCamelCase(str) {
    return lowerCaseFirstLetter(
        str
            .split("-")
            .map((piece) => upperCaseFirstLetter(piece))
            .join("")
    );
}

/**
 * @param {*} str
 * @returns {string}
 */
export function camelToKebabCase(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

/**
 *
 * @param {string} str
 * @returns {string}
 */
export function snakeToCamelCase(str) {
    return lowerCaseFirstLetter(
        str
            .split("_")
            .map((piece) => upperCaseFirstLetter(piece))
            .join("")
    );
}

/**
 * Validate if value is in the right range.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {boolean}
 */
export function validRange(value, min, max) {
    return value >= min && value <= max;
}

/**
 * Formats date into ISO string pattern.
 * @param {Date} date
 */
export function formatISODate(date) {
    return date.toISOString().slice(0, 10);
}

/**
 * Gets a format pattern and apply it to a value accordingly.
 * @param {string} format
 * @param {number|string} value
 * @returns {[number|string, string]}
 */
export function parseFormat(format, value, nan = false) {
    const _value = value ? parseFloat(value).toFixed(2) : value;

    if (nan && isNaN(_value)) {
        return value;
    }

    switch (format) {
        case "0.00D":
            return [_value, "D"];
        case "0.00mm":
            return [_value, "mm"];
        case "0":
        default:
            return [value, ""];
    }
}

/**
 * @param {string} str
 * @param {string} pattern
 * @returns {boolean}
 */
export function like(str, pattern) {
    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp("^" + `.*${escapedPattern}.*` + "$", "i");
    return regex.test(str);
}

/**
 * Format date to dd/mm/YYYY format.
 * @param {string} inputDate
 * @returns {string}
 */
export function formatDate(inputDate) {
    const date = new Date(inputDate);

    return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(date);
}

/**
 * Format Date object to string yyyy-mm-dd.
 * @param {Date} date
 * @returns {string}
 */
export function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

/**
 * Format dd/mm/yyyy to yyyy/mm/dd.
 * @param {string} dateStr
 * @returns {string}
 */
export function formatStringToYMD(dateStr) {
    const [day, month, year] = dateStr.split("/");
    return `${year}-${month}-${day}`;
}

/*
 * Check if user agent is a mobile device.
 * @returns {boolean}
 */
export function isMobileUserAgent() {
    return !(navigator.userAgent.indexOf("Mobile") <= 0);
}

/**
 * Converts a string input into a boolean
 * value based on a list of valid options.
 * @returns {boolean}
 */
export function stringToBoolean(input, validOptions = ["true", "false"]) {
    const inputLower = input.toLowerCase();

    if (!validOptions.includes(inputLower)) {
        return false;
    }

    return inputLower === "true";
}

/**
 * Checks if all values in an array are non-empty values.
 * @param {any[]} values
 * @returns {boolean}
 */
export function areAllValuesPresent(values) {
    return values.every((value) => !!value || value === 0);
}

/**
 * Calculates the absolute time difference
 * between two dates in years.
 * @param {Date} date1
 * @param {Date} date2
 * @returns {float}
 */

export function calculateYearDifference(date1, date2) {
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(diff / 365.25);
}

/**
 * Checks if there are some dates where the time difference in years
 * between them are lower than the given limit.
 * @param {string[]} dates
 * @param {float} limit
 * @returns {boolean}
 */
export function areDatesBelowLimit(dates, limit) {
    const sortedDates = [...dates].sort((a, b) => new Date(a) - new Date(b));

    for (let i = 0; i < sortedDates.length - 1; i++) {
        const first = new Date(sortedDates[i]);
        const second = new Date(sortedDates[i + 1]);

        if (calculateYearDifference(first, second) < limit) {
            return true;
        }
    }

    return false;
}

/**
 * Check if value is empty.
 * @param {*} value
 */
export function empty(value) {
    return value == null || value === "";
}

/**
 * Check if element object is a form.
 * @param {HTMLElement} elementObject
 * @returns {boolean}
 */
export function isForm(elementObject) {
    return elementObject instanceof HTMLFormElement;
}

/**
 * Strips name to only retain first name
 */
export function stripNameInput() {
    const nameField = document.querySelector("input[name='name']");

    if (nameField) {
        const [firstName] = nameField.value.split(" ");
        nameField.value = firstName;
    }
}

/**
 * Checks if a string is a number.
 * @param {string} value
 * @returns {boolean}
 */
export function isNumericString(value) {
    return !isNaN(Number(value));
}

/**
 * Strip trailing numbers from a string based on a given
 * pattern.
 * @param {string} value
 * @param {RegExp} pattern
 * @returns
 */
export function stripTrailingNumbers(value, pattern = /-\d+$/) {
    if (pattern.test(value)) {
        return value.replace(pattern, "");
    }

    return value;
}

/*
 * Submits a validation error for a form
 * @param form
 * @param {string} formType
 */
export function submitFormValidationError(form, formType) {
    const formData = new FormData(form);
    const { formJSON, csrfToken } = formatFormErrorSubmission(formData);

    fetch("/api/integration/log_error/report/" + formType, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(formJSON),
    })
        .then((response) => {
            if (!response.ok) {
                console.error("Failed to log error:", response.statusText);
            }
        })
        .catch((error) => {
            console.error("Error while posting validation failure:", error);
        });
}

/**
 * Formats the submission and extracts a CSRF Token
 * @param {FormData} formData
 */
export function formatFormErrorSubmission(formData) {
    const formJSON = {};
    let csrfToken =
        document.querySelector("input[name='csrf_token']")?.value || "";
    const doNotSend = ["name", "dob_date"];
    formData.forEach((value, key) => {
        if (key === "csrf_token") {
            csrfToken = value;
        } else if (doNotSend.includes(key)) {
            formJSON[key] = value ? "Set" : "Not set";
        } else {
            formJSON[key] = value;
        }
    });
    return { formJSON, csrfToken };
}

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");

    for (const element of ca) {
        let c = element.trim();
        if (c.startsWith(nameEQ)) {
            return c.substring(nameEQ.length);
        }
    }
    return null;
}

/**
 * Checks if the current page is either the login page or the register page.
 *
 * @returns {boolean} true if the current page is the login or register page, otherwise false.
 */
export function isLoginOrRegisterPage() {
    const loginPage = document.getElementById("login-page");
    const registerPage = document.getElementById("register-page");

    return !!loginPage || !!registerPage;
}

/**
 * Determines the platform type of the user's browser device.
 *
 * @returns {string} The platform type of the device (e.g., "desktop", "mobile", "tablet").
 */
export function getBrowserDevice() {
    return browser.getPlatformType();
}

/**
 * Checks if the user's browser satisfies the specified version requirements
 * or if the device is a tablet.
 *
 * @returns {boolean} - Returns `true` if the browser meets the version requirements
 *                      or the device is identified as a tablet; otherwise, `false`.
 */
export function isValidBrowser() {
    const isValid = browser.satisfies(SUPPORTED_BROWSERS_BOWSER_CONFIG);

    return isValid === true || getBrowserDevice() === "tablet";
}
