/* eslint-disable camelcase */
import { z } from "zod";
import { upperCaseFirstLetter } from "../../../utils";

export const stringSchema = (error, max = 100) =>
    z
        .string({
            message: error?.string,
            required_error: error?.required,
            invalid_type_error: error?.required,
        })
        .min(1, error?.min)
        .max(max, error?.max);

export const nullishStringSchema = (error, max = 100) =>
    z.string({ message: error?.string }).max(max, error?.max).nullish();

export const emailSchema = (error) =>
    z
        .string({ message: error?.string })
        .email(error?.email)
        .max(100, error?.max);

export const passwordSchema = (error) =>
    z.coerce
        .string({ message: error?.string })
        .min(6, error?.min)
        .max(60, error?.max)
        .refine((value) => /[a-z]/.test(value), error?.lowercase)
        .refine((value) => /[A-Z]/.test(value), error?.uppercase)
        .refine((value) => /\d/.test(value), error?.number)
        .refine((value) => /\W/.test(value), error?.special);

export const truthyBooleanSchema = (error) =>
    z.coerce
        .boolean({ message: error.boolean })
        .refine((value) => value === true, {
            message: error.true,
        });

export const rangeSchema = (min, max, error) =>
    z.coerce
        .number({
            required_error: error?.required,
            invalid_type_error: error?.required,
        })
        .gte(min, { message: error?.gte })
        .lte(max, { message: error?.lte });

export const optionalRangeSchema = (min, max, error) =>
    z.coerce
        .number({
            required_error: error?.required,
            invalid_type_error: error?.required,
        })
        .gte(min, { message: error?.gte })
        .lte(max, { message: error?.lte })
        .nullish();

export const dateSchema = (error) => {
    return z.coerce
        .date({
            errorMap: () => {
                return { message: error?.required };
            },
        })
        .max(new Date(), { message: error?.max });
};

export const dateNumberSchema = (error, max) =>
    z.coerce
        .number({
            required_error: error?.required,
            invalid_type_error: error?.required,
        })
        .positive(error?.positive)
        .lte(max, { message: error?.lte });

export const dateYearSchema = (error, max) =>
    z.coerce
        .number({
            required_error: error?.required,
            invalid_type_error: error?.required,
        })
        .nonnegative(error?.positive)
        .lte(max, { message: error?.lte });

export const linkedSchema = (field, schema, error) => {
    const link = `link${upperCaseFirstLetter(field)}`;
    return {
        [field]: schema(error?.[field]),
        [link]: schema(error?.[link]),
    };
};

export const dependentField = (fieldKey, dependentKey, message) => {
    return [
        ({ [fieldKey]: field, [dependentKey]: dependent }) =>
            !(field && !dependent),
        {
            message: message,
            path: [dependentKey],
        },
    ];
};

export const dependentFields = (
    fieldKey,
    dependentKey,
    message,
    dependentMessage
) => {
    return [
        ({ [fieldKey]: field, [dependentKey]: dependent }) =>
            !(field && !dependent),
        { message: message, path: [dependentKey] },
        ({ [dependentKey]: dependent, [fieldKey]: field }) =>
            !(dependent && !field),
        { message: dependentMessage, path: [fieldKey] },
    ];
};

export const demographicPatientDataSchema = (errorObject) => {
    return z.object({
        name: stringSchema(errorObject?.name),
        patientId: nullishStringSchema(errorObject?.patientId),
        visitDate: dateSchema(errorObject?.visitDate),
        sex: stringSchema(errorObject?.sex),
        ethnicity: stringSchema(errorObject?.ethnicity),
        dobDate: dateSchema(errorObject?.dobDate),
    });
};

export const demographicPatientDataWithTreatmentSchema = (errorObject) => {
    return z.object({
        name: stringSchema(errorObject?.name),
        patientId: nullishStringSchema(errorObject?.patientId),
        visitDate: dateSchema(errorObject?.visitDate),
        sex: stringSchema(errorObject?.sex),
        ethnicity: stringSchema(errorObject?.ethnicity),
        dobDate: dateSchema(errorObject?.dobDate),
        hasUsedTreatment: stringSchema(errorObject?.hasUsedTreatment),
    });
};
