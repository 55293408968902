import { isBaseRowEmpty } from "./form_validator/extensions/collection/common/visit_repeater";
import { FormValidator } from "./form_validator/form_validator";
import { stripNameInput } from "./utils";

const MS_PER_YEAR = 365.2425 * 24 * 60 * 60 * 1000;

/**
 * Calculate age from a referencial date.
 * @param {Date} dob
 * @param {Date} refDate
 * @returns {number}
 */
export function calculateAgeInDecimal(dob, refDate = new Date()) {
    const diffMs = refDate - dob;
    return (diffMs / MS_PER_YEAR).toFixed(1);
}

/**
 * Create age input to avoid sending date of birth
 * information to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 */
export function createAgeInput(dob, context) {
    const age = calculateAgeInDecimal(dob);
    createHiddenInput("age", age, context);
}

/**
 * Create age input to avoid sending date of birth information to the backend,
 * calculating age based on the visit date.
 * @param {Date} dob
 * @param {Date} visitDate
 * @param {HTMLElement} context
 */
export function createAgeInputAtVisit(dob, visitDate, context) {
    const age = calculateAgeInDecimal(dob, visitDate); // Calculate age based on the visit date
    createHiddenInput("age", age, context);
}

/**
 * Create hidden input element.
 * @param {string} name
 * @param {*} value
 * @param {HTMLElement} context
 */
export function createHiddenInput(name, value, context) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    context.appendChild(input);
}

/**
 * Create age list input to avoid sending date of birth information
 * to the backend.
 * @param {Date} dob
 * @param {HTMLElement} context
 * @param {string} name
 */
export function createAgeListInput(dob, context, name, repeaterId) {
    const dates = document.getElementsByName(name);
    const ages = [];
    const [isEmpty] = isBaseRowEmpty(repeaterId);

    dates.forEach((dateElement) => {
        if (isEmpty && dateElement.id === name) {
            return;
        }

        if (dateElement.value) {
            const date = new Date(dateElement.value);
            const age = calculateAgeInDecimal(dob, date);
            ages.push(age);
        } else {
            ages.push("");
        }
    });

    const ageName = name.replace("date", "ages");
    createHiddenInput(ageName, JSON.stringify(ages), context);
}

/**
 * Calculate age from dob input on meye forms.
 * @param {HTMLFormElement} form
 */
function onSubmitMeyeWithDob(form) {
    const dobDateInput = document.getElementById("dob-date");
    const dob = new Date(dobDateInput.value);

    const visitDateInput = document.getElementById("visit_date");
    const visitDate = new Date(visitDateInput.value);

    createAgeInputAtVisit(dob, visitDate, form);
    dobDateInput.disabled = true;
}

/**
 * Calculate age from dob input on meye score forms.
 * @param {HTMLFormElement} form
 */
export function onSubmitScoreWithDob(form) {
    const dobInput = document.getElementById("dob-date");
    if (dobInput) {
        const dob = new Date(dobInput.value);
        createAgeListInput(dob, form, "visit-date", "visit-input-repeater");
        createAgeListInput(dob, form, "start-date", "treatment-input-repeater");
        createAgeListInput(dob, form, "stop-date", "treatment-input-repeater");
        dobInput.disabled = true;
    }
}

/**
 * Adds listener to meye forms to handle age calculations.
 * @param {HTMLFormElement} form
 * @param {boolean} validate
 * @param {() => void | undefined} beforeSubmit
 */
function handleMeyeDob(form, validate = false, beforeSubmit = undefined) {
    if (!form) {
        return;
    }

    form.addEventListener("submit", (event) => {
        event.preventDefault();
        const shouldSubmit =
            !validate || FormValidator.getInstance(form.id).validateForm();

        if (shouldSubmit) {
            onSubmitMeyeWithDob(form);
            beforeSubmit?.();
            form.submit();
        }
    });
}

/**
 * Create hidden inputs with age values calculated from the visit dates,
 * start dates and stop dates.
 */
export function treatDobInputs() {
    const guideForm = document.getElementById("submit-guide-form");
    const alestForm = document.getElementById("submit-alest-form");

    handleMeyeDob(guideForm, true);
    handleMeyeDob(alestForm, false, stripNameInput);
}

/**
 * Enable DOB inputs on unload page.
 */
export function onUnloadPageWithDob() {
    window.addEventListener("beforeunload", () => {
        const dateInput = document.getElementById("dob-date");
        if (dateInput) {
            dateInput.disabled = false;
        }
    });
}
