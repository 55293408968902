import "./jquery_global";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/tab";
import Popover from "bootstrap/js/dist/popover";
import { DefaultAllowlist } from "bootstrap/js/dist/util/sanitizer";
import intlTelInput from "intl-tel-input";
import "./libs/input_validation";
import { toPng } from "html-to-image";
import { getDate, validDate } from "./libs/date_of_birth";
import {
    displayErrorOnInput,
    removeError,
    validator,
} from "./libs/error_validation_system";
import {
    animateNumericProgression,
    formatISODate,
    isSafari,
    roundIntToQuarter,
    swapClasses,
    validRange,
    valueInRange,
    isLoginOrRegisterPage,
    browser,
    isValidBrowser,
    getBrowserDevice,
    setCookie,
    getCookie,
} from "./libs/utils";
import {
    initVisionSimulator,
    parseCylinderAxisValue,
    postMessageSimulatorCtaButton,
} from "./libs/vision_simulator";
import { initArcRangeInput } from "./libs/arc_range_input";
import { getValKR, initKrInput } from "./libs/kr_inputs";
import { initLinkedTabInputs, initLinkedToggles } from "./libs/linked_elements";
import { initUiKitTooltips } from "./libs/tooltip";
import {
    FormValidator,
    initFormValidator,
} from "./libs/form_validator/form_validator";
import {
    applySelectStyle,
    initThemeToggle,
    initDynamicThemeChange,
} from "./libs/theme";
import "intl-tel-input/build/js/utils";
import { initSidebarCollapseAppearance } from "./libs/sidebar";
import { computeScale } from "./libs/math";
import { initRepeater } from "./libs/repeater";
import { initAutoCompleteSelect } from "./libs/autocomplete_select";
import { onUnloadPageWithDob, treatDobInputs } from "./libs/age";
import { MIN_SUPPORTED_BROWSER_VERSIONS } from "./libs/constants";

function blockTabIndexes() {
    const blockTabs = document.getElementsByClassName("block-tab");
    for (const blockTab of blockTabs) {
        blockTab.tabIndex = "-1";
    }
}

/**
 * Set the active link for the clicked link only if the navbar exists
 */
function setActiveNavBar() {
    const navbar = document.getElementById("nav");

    if (navbar != null) {
        // Get all the item link
        const aItems = navbar.querySelectorAll(".navbar-nav a");
        // Set the link to active and change the title when the last parameter hrefs are the same
        for (const aItem of aItems) {
            if (getLastParam(location.href) === getLastParam(aItem.href)) {
                aItem.classList.add("active");
                setTitleOcumetra(aItem.text);
            }
        }
    }
}

/**
 * Get last parameter of an href
 * @param {string} href - url
 * @returns last parameter of an href
 */
export function getLastParam(href) {
    return href
        .split("/")
        .filter((item) => item != "")
        .slice(-1)[0];
}

/**
 * Set the title of the browser tab that first contains Ocumetra and then the name of the tool.
 * @param {string} newTitle - the name of the tool
 */
function setTitleOcumetra(newTitle) {
    let title = "Ocumetra";
    const newTitleNoSpace = newTitle.trim();
    if (newTitleNoSpace !== "") {
        title += " - " + newTitleNoSpace;
    }
    document.title = title;
}

/**
 * Show or Hide the password entered in the password input
 * @param {*} btn
 */
function showHidePassword(btn) {
    const input = $(btn).siblings("input");
    if ($(btn).hasClass("hide")) {
        $(btn).removeClass("hide");
        $(btn).addClass("show");
        $(input).prop("type", "text");
    } else {
        $(btn).removeClass("show");
        $(btn).addClass("hide");
        $(input).prop("type", "password");
    }
}

/**
 * Init the event listener
 */
function initEventListener() {
    $(".show-hide-password-btn").on("click", function () {
        showHidePassword(this);
    });

    $(".valid-regexp").on("keypress", function (e) {
        return isValidKeyRegExp(this, e);
    });

    $(".dropdown-toggle").on("click", function () {
        const selectpicker = $(this).siblings(".selectpicker");
        removeError(selectpicker);
    });

    $(".link-required").on("input blur", function () {
        setRequiredLinkedInput(this);
    });

    $(".help-icon").on("click", function () {
        displayHelpOverlay(this);
    });

    $(".btn-close-overlay").on("click", function () {
        hideHelpOverlay(this);
    });

    $(".KR-radio").on("change", function () {
        clickRadioKR(this);
    });

    $(".KR-input").on("blur", function () {
        outInputKR(this);
    });

    $("#visit-date").on("change", function () {
        validVisitDate(this);
    });

    $("#open-share").on("click", function () {
        openShareButton(this);
    });

    $("#userForm").on("submit", function (e) {
        setSubmit(e, this);
    });
}

/**
 * Init the intl-tel-input plugin and set the preferred countrie to Ireland (ie)
 */
function initTelInputPlugin() {
    const inputPhone = document.querySelector("#phone");

    if (inputPhone) {
        const iti = intlTelInput(inputPhone, {
            initialCountry: "ie",
            preferredCountries: ["au", "ca", "ie", "nz", "gb", "us"],
        });

        inputPhone.value = iti.getNumber();

        inputPhone.addEventListener("blur", () => {
            inputPhone.value = iti.getNumber();
        });
    }
}

/**
 * Call the submit when the Proceed button is clicked (in layouts/base/tools.html)
 * @param {*} event - event from submit button
 * @param {*} form
 */
function setSubmit(event, form) {
    event.preventDefault();
    // Call the validator function (in error_validation_system) before making the Ajax call
    if (validator()) {
        // All inputs are validated, so submit the form to the back-end.
        if (!isSafari()) {
            launchAnimationWaitingDiv();
        }
        form.submit();
    }
}

/**
 * On Keypress : Check if the key is valid according to the regex pattern
 * Useful for binding the inputs when a key is pressed
 * @param {*} input
 * @param {*} e - event from keypress
 * @returns
 */
function isValidKeyRegExp(input, e) {
    const key = e.key || e.code;
    if (key !== "Enter") {
        const startStr = input.value.substring(0, input.selectionStart);
        const endStr = input.value.substring(input.selectionEnd);
        // ^[+-]?([0-9]{0,})*[.]?([0-9]{0,2})?$  ==> Pattern for floats
        const { pattern } = input;
        const regex = new RegExp("^" + pattern + "$", "g");
        return regex.test(startStr + key + endStr);
    }
    // Click on Enter => trigger the submit function
    return true;
}

/**
 * Set the required on the input and its inputLink
 * Useful for the Cylinder and Axis inputs
 * @param {*} input
 */
export function setRequiredLinkedInput(input) {
    if ($(input).data("link")) {
        const IDInputLinked = $(input).data("link");
        const inputLinked = $("#" + IDInputLinked);
        // If the input and its inputLink have no value, then required false otherwise true
        if (
            ($(input).val().length === 0 &&
                $(inputLinked).val().length === 0) ||
            $(input).attr("default") === $(input).val()
        ) {
            $(input).prop("required", false);
            $(inputLinked).prop("required", false);
            $(inputLinked).parents(".card-range").removeClass("card-required");
            removeError(input);
            removeError(inputLinked);
        } else {
            $(input).prop("required", true);
            $(inputLinked).prop("required", true);
            $(inputLinked).parents(".card-range").addClass("card-required");
        }
    }
}

/**
 * On Click : the help icon (I): Display or Hide the Help Overlay info
 * @param {*} helpIcon
 */
function displayHelpOverlay(helpIcon) {
    // Get the restId = second split element [1]
    const [, restId] = helpIcon.id.split("-");
    const idHelpOverlay = "#helpOverlay-" + restId;
    setHelpOverlayHeightPos(idHelpOverlay);
    if ($(idHelpOverlay).css("display") === "none") {
        $(idHelpOverlay).slideDown(); // Show the info text
    } else {
        $(idHelpOverlay).slideUp(); // Hide the info text
        $(idHelpOverlay).css("display", "none");
    }
}

/**
 * Set the position and the height of the Help Overlay
 * @param {*} idHelpOverlay
 */
function setHelpOverlayHeightPos(idHelpOverlay) {
    const posYFullResult = $("#full-result-container").offset().top;
    const posYDataCard = $(".bg-data-card").offset().top;
    const posYHelpOverlay = posYDataCard - posYFullResult;
    $(idHelpOverlay).css("top", posYHelpOverlay + "px");
    $(idHelpOverlay).css("height", "calc(100% - " + posYHelpOverlay + "px)");
}

/**
 * On Click : Hide the Help Overlay info
 * @param {*} btn
 */
function hideHelpOverlay(btn) {
    const overlay = btn.parentElement;
    // Get the restId = second split element [1]
    const [, restId] = overlay.id.split("-");
    const idHelpIcon = "#helpIcon-" + restId;
    $(overlay).slideUp(); // Hide the info text
    $(idHelpIcon).attr("title", $(idHelpIcon).data("title-show")); // Set up help hover
}

/**
 * Returns a file name
 * @returns string composed of : Tool title - Name - Current date
 */
function getFileName() {
    return (
        $("#main-title").text() +
        " - " +
        capitalizeFirstLetter($("#report-name").text()) +
        " - " +
        getDate()
    );
}

/**
 * Return a string with the first letter capitalized
 * @param {string} str
 * @returns string with the first letter capitalized
 */
export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Print the output page
 */
function printReport() {
    const toolTitle = document.getElementById("main-title");
    const eyeContent = document.querySelector("#full-result-container");
    const infoContent = document.querySelector(".help-content");

    toPng(eyeContent).then(function (dataUrl) {
        const prnt = window.open("", "", "height=700, width=800");
        prnt.document.write(
            "<html><head><title>" +
                toolTitle.innerHTML +
                "</title></head><body>"
        );

        prnt.document.write("<div id='eye-content'>");
        const img = new Image();
        img.src = dataUrl;
        prnt.document.write(img.outerHTML);
        prnt.document.write("</div>");

        prnt.document.write("<div id='info-content'>");
        prnt.document.write(infoContent.outerHTML);
        prnt.document.write("</div>");
        prnt.document.write("</body></html>");

        // PDF print and close window
        prnt.document.close();
        prnt.focus();
        prnt.print();
        prnt.close();
        delete img.src;
    });
}

/**
 * Download the output page
 */
function downloadReport() {
    toPng(document.querySelector("#full-result-container")).then(function (
        dataUrl
    ) {
        const img = { printing: dataUrl };
        const filename = getFileName() + ".png";
        const a = document.createElement("a");
        a.href = img.printing;
        a.download = filename || "download";
        document.body.appendChild(a);
        a.click();
        delete img.printing;
    });
}

/**
 * Return the getValKR but fixed 2 (Two digits after the decimal point)
 * @param {*} val - original value
 * @returns getValKR
 */
function getValKRFixed2(val) {
    return getValKR(val).toFixed(2);
}

/**
 * Calculate and get the Refraction SER
 * @param {number} sphere
 * @param {number} cylinder
 * @returns the Refraction SER
 */
export function getRefraction(sphere, cylinder) {
    return (parseFloat(sphere) + parseFloat(cylinder / 2)).toFixed(2);
}

/**
 * Calculate and get the Corneal
 * @param {number} k1
 * @param {number} k2
 * @returns the Corneal
 */
export function getCorneal(k1, k2) {
    return ((parseFloat(k1) + parseFloat(k2)) / 2).toFixed(2);
}

/**
 * Check if the visit date is valid and show an error if it's not
 * @param {*} input - visit-date input
 * @returns true/false
 */
export function validVisitDate(input) {
    if (input.value !== null && input.value !== "") {
        if (!validDate(input.value)) {
            displayErrorOnInput($(input), $("#error-msgs").data("date"));
            return false;
        }
        removeError($(input), $("#error-msgs").data("fill-in"));
        return true;
    }
    displayErrorOnInput($(input), $("#error-msgs").data("date"));
    return false;
}

/**
 *  On Click radio button : Change the value of K/R and the indicator
 * @param {*} radio - radio button
 */
function clickRadioKR(radio) {
    // The new value (K or R) according to the radio button clicked
    const newKRValue = radio.value.charAt(0);
    // Call function to change all radio buttons to K or R
    changeRadioKR(newKRValue);
}

/**
 * Get KR input name.
 * @param {HTMLInputElement} input
 * @param {string} oppositeValue
 * @param {string} value
 * @returns {string}
 */
function getKRInputName(input, oppositeValue, value) {
    const currentName = input.getAttribute("name").split("-");
    return `${currentName[0]}-${currentName[1].replace(
        oppositeValue.toLowerCase(),
        value.toLowerCase()
    )}`;
}

/**
 * Change the value of all radio buttons to K or R according to the KRValue.
 * @param {string} KRValue - "K" or "R"
 */
function changeRadioKR(KRValue) {
    const krRadios = document.querySelectorAll(`.radio-${KRValue}`);
    const cardRangeKRs = document.querySelectorAll(".KR-card-range");
    const pickKR = document.querySelector("input[name=pick_kr]");
    const oppositeKRValue = KRValue === "K" ? "R" : "K";

    pickKR.value = KRValue.toLowerCase();

    krRadios.forEach((radio) => {
        radio.checked = true;
    });

    cardRangeKRs.forEach((cardRangeKR) => {
        const sliderRangeKR = cardRangeKR.querySelector(".slider-range");
        const inputRangeKR = cardRangeKR.querySelector(".input-range");

        inputRangeKR.setAttribute(
            "name",
            getKRInputName(inputRangeKR, oppositeKRValue, KRValue)
        );
        sliderRangeKR.setAttribute(
            "name",
            getKRInputName(sliderRangeKR, oppositeKRValue, KRValue)
        );

        const indicationValueKR = cardRangeKR.querySelector(
            `.indication-value-range-${KRValue}`
        );
        const oppositeIndicationValueKR = cardRangeKR.querySelector(
            `.indication-value-range-${oppositeKRValue}`
        );

        swapClasses(
            indicationValueKR,
            "indication-value",
            "indication-value-none"
        );
        swapClasses(
            oppositeIndicationValueKR,
            "indication-value-none",
            "indication-value"
        );

        const min = parseFloat(
            indicationValueKR.querySelector(".min").innerText
        );
        const max = parseFloat(
            indicationValueKR.querySelector(".max").innerText
        );

        let newValue = getValKR(sliderRangeKR.value);

        if (newValue < min) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        }

        sliderRangeKR.setAttribute("min", min);
        sliderRangeKR.setAttribute("max", max);
        sliderRangeKR.setAttribute("value", newValue);
        sliderRangeKR.value = newValue;

        if (cardRangeKR.classList.contains("card-change")) {
            inputRangeKR.value = newValue.toFixed(2);
            inputRangeKR.setAttribute("value", newValue.toFixed(2));
        }
    });
}

/**
 * Focusout Input KR
 * Change K to R or vice versa if the value entered is within the range (min/max) of the opposite values
 * @param {*} input
 */
function outInputKR(input) {
    const val = input.value;
    const [radioName] = input.id.split("-");
    const sliderRangeKR = $("#" + radioName + "-slider");
    // Get the opposite range (min/max) with the getValKR function
    const min = getValKRFixed2(sliderRangeKR.attr("max"));
    const max = getValKRFixed2(sliderRangeKR.attr("min"));
    // If the value entered is within the range, we change K to R or vice versa
    if (val >= min && val <= max) {
        // Get the new value and set sliderRangeKR value
        const newVal = getValKR(val);
        $(sliderRangeKR).val(newVal);
        $(sliderRangeKR).attr("value", newVal); // has more decimal
        // Get the current ID of the checked radio button and its opposite
        const idRadioChecked = $(
            "input[name='" + radioName + "']:checked"
        ).attr("id");
        const oppositeIDRadio = idRadioChecked.charAt(0) === "K" ? "R" : "K";
        // Simulates a click on the opposite radio button and change the value of all radio buttons to K or R
        changeRadioKR(oppositeIDRadio);
    }
}

/**
 * Init all cards range
 */
export function initCardRange() {
    const cardsRange = document.querySelectorAll(".card-range");
    const events = ["input", "click"];

    cardsRange.forEach((cardRange) => {
        const sliderRange = cardRange.querySelector(".slider-range");
        const inputRange = cardRange.querySelector(".input-range");
        const indicationValue = cardRange.querySelector(".indication-value");

        initSliderRangeAndInputRange(sliderRange, inputRange, indicationValue);

        events.forEach((eventType) => {
            sliderRange.addEventListener(eventType, () => {
                onInputSliderRange(cardRange, inputRange, sliderRange);
            });
        });

        inputRange.addEventListener("keyup", (e) => {
            const key = e.key || e.code;
            onKeyupInputRange(key, cardRange, inputRange, sliderRange);
        });

        inputRange.addEventListener("focusout", () => {
            onFocusoutInputRange(cardRange, inputRange, sliderRange);
        });
    });
}

/**
 * Show/hide plus sign in input.
 * @param {HTMLElement} container
 * @param {HTMLInputElement} inputRange
 */
function updatePlusSign(container, inputRange) {
    const plusSign = container.querySelector(".plus-sign");
    const { value } = inputRange;

    if (isNaN(value) || value === "" || value <= 0) {
        inputRange.style.paddingLeft = `${8}px`;
        plusSign.classList.add("d-none");
    } else {
        inputRange.style.paddingLeft = `${18}px`;
        plusSign.classList.remove("d-none");
    }
}

/**
 *  On Click and Input : applies the changes for the sliderRange
 * @param {HTMLElement} cardRange
 * @param {HTMLInputElement} inputRange
 * @param {HTMLInputElement} sliderRange
 */
function onInputSliderRange(cardRange, inputRange, sliderRange) {
    cardRange.classList.add("card-change");
    const { value } = sliderRange;

    const decimalPlaces = sliderRange.max == 180 ? 0 : 2;
    inputRange.value = parseFloat(value).toFixed(decimalPlaces);
    setRequiredLinkedInput(inputRange);
    removeError(inputRange);
    if (cardRange.classList.contains("plus-sign-container")) {
        updatePlusSign(cardRange, inputRange);
    }
}

/**
 * On Keyup : applies the changes for the inputRange
 * @param {string} key - keyboard key code
 * @param {HTMLElement} cardRange
 * @param {HTMLInputElement} inputRange
 * @param {HTMLInputElement} sliderRange
 */
function onKeyupInputRange(key, cardRange, inputRange, sliderRange) {
    // Check if the key is NaN, yet it results in a numeric value.
    const isKeyValueValid =
        !!inputRange.value && !isNaN(inputRange.value) && isNaN(key);
    const allowedNaNKeys = ["Backspace", "Tab"];

    if (cardRange.classList.contains("plus-sign-container")) {
        updatePlusSign(cardRange, inputRange);
    }

    if (!isNaN(key) || allowedNaNKeys.includes(key) || isKeyValueValid) {
        cardRange.classList.add("card-change");
        const value = parseFloat(inputRange.value);
        const min = parseFloat(sliderRange.getAttribute("min"));

        if (cardRange.classList.contains("plus-sign-container")) {
            updatePlusSign(cardRange, inputRange);
        }

        const valueLength = value.toString().length;
        const valueValidDigits = min < 0 ? 0 : parseInt(min).toString().length;

        if (valueLength >= valueValidDigits) {
            sliderRange.value = value;
            sliderRange.setAttribute("value", value);
        }
    }
}

/**
 * On Focusout : applies the changes for the inputRange
 * @param {HTMLElement} cardRange
 * @param {HTMLInputElement} inputRange
 * @param {HTMLInputElement} sliderRange
 */
function onFocusoutInputRange(cardRange, inputRange, sliderRange) {
    if (cardRange.classList.contains("card-change")) {
        const value = parseFloat(inputRange.value);
        const min = parseFloat(sliderRange.getAttribute("min"));
        const max = parseFloat(sliderRange.getAttribute("max"));

        if (!validRange(value, min, max)) {
            displayErrorOnInput(
                $(inputRange),
                $("#error-msgs").data("outrange")
            );
            return;
        }

        inputRange.value = parseCylinderAxisValue(
            inputRange.id,
            inputRange.value
        );
        inputRange.setAttribute(
            "value",
            parseCylinderAxisValue(inputRange.id, inputRange.value)
        );
        if (sliderRange.getAttribute("step") === "0.25") {
            inputRange.value = roundIntToQuarter(value).toString();
        }
    } else {
        inputRange.value = "";
    }
}

/**
 * Init the min, max for the sliderRange
 * Init the value for sliderRange and inputRange
 * @param {HTMLInputElement} sliderRange
 * @param {HTMLInputElement} inputRange
 * @param {HTMLElement} indicationValue
 */
function initSliderRangeAndInputRange(
    sliderRange,
    inputRange,
    indicationValue
) {
    const min = parseFloat(indicationValue.querySelector(".min").innerText);
    const max = parseFloat(indicationValue.querySelector(".max").innerText);
    const defaultValue = min + (max - min) / 2;

    sliderRange.setAttribute("min", min);
    sliderRange.setAttribute("max", max);

    if (sliderRange.getAttribute("value") == null) {
        sliderRange.value = defaultValue;
        sliderRange.setAttribute("value", defaultValue);
    }

    inputRange.setAttribute("default", defaultValue);
}

/**
 * Reset every fields and value in CardRange
 */
export function resetCardRange() {
    // clear current values
    $(".card-range").removeClass("card-change");
    $(".card-range").removeClass("card-required");
    // reset value for the inputs
    $(".input-range").val("");
    // reset radio button to K
    changeRadioKR("K");
}

/**
 * Get the name of the Bowser that we are using
 * @returns the Bowser's name
 */
export function getBrowserName() {
    if (
        navigator.userAgent.indexOf("Edge") > -1 &&
        navigator.appVersion.indexOf("Edge") > -1
    ) {
        return "Edge";
    } else if (
        navigator.userAgent.indexOf("Opera") !== -1 ||
        navigator.userAgent.indexOf("OPR") !== -1
    ) {
        return "Opera";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return "Chrome";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return "Firefox";
    } else if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        !!document.documentMode
    ) {
        // IF IE > 10
        return "IE";
    }
    return "unknown";
}

/**
 * For the fields with the "link-required" class, disable the required on the filed
 * Useful because some fields (like cylinder or axis) need to have a required (for the error-validation-system)
 * But start with a required = false
 */
function disableRequiredField() {
    $(".link-required").prop("required", false);
}

/**
 * Init the sharing links
 */
function initShareLinks() {
    $("#share-by-print-report").on("click", function () {
        printReport();
    });
    $("#share-by-download-png").on("click", function () {
        downloadReport();
    });
}

/**
 * On Click : Open the share link buttons
 * @param {*} shareButton
 */
function openShareButton(shareButton) {
    const parent = $(shareButton).parent();
    const shareButtons = document.querySelectorAll(".share-button");
    if ($(parent).hasClass("show")) {
        $(parent).removeClass("show");
        // Close all share buttons (set the bottom position to 0)
        for (const shareBtn of shareButtons) {
            shareBtn.style.bottom = "0";
        }
    } else {
        $(parent).addClass("show");
        // Open all share buttons (change the bottom position)
        for (let i = shareButtons.length - 1, j = 1; i >= 1; i--, j++) {
            shareButtons[i].style.bottom = "calc(70px * " + j + ")";
        }
    }
}

/**
 * Remove background video on Safari since it doesn't support
 * webm alpha channel.
 */
function removeVideoOnSafari() {
    const video = document.querySelector(".background-video");
    const { userAgent } = navigator;

    const isSafari =
        userAgent.indexOf("Safari") > -1 &&
        userAgent.indexOf("Chrome") < 0 &&
        userAgent.indexOf("Firefox") < 0 &&
        userAgent.indexOf("Chrome") < 0;

    if (isSafari && video) {
        const fallbackGradient = document.querySelector(".gradient-fallback");
        video.remove();
        fallbackGradient.style.display = "block";
    }
}

/**
 * Scroll into alert messages when they exist.
 * on the page.
 */
function focusAlert() {
    const alert = document.querySelector(".focus-alert");

    if (alert) {
        alert.scrollIntoView(true, {
            behavior: "smooth",
        });
    }
}

/**
 * Set toggle decoration position according to selected option.
 * @param {HTMLInputElement[]} toggleInputs
 * @param {HTMLElement} toggleDecoration
 *
 */
function setToggleDecorationPosition(toggleInputs, toggleDecoration) {
    if (toggleInputs[0]?.checked) {
        swapClasses(toggleDecoration, "first-selected", "second-selected");
    } else if (toggleInputs[1]?.checked) {
        swapClasses(toggleDecoration, "second-selected", "first-selected");
    } else {
        toggleDecoration.classList.remove("first-selected", "second-selected");
    }
}

/**
 * Initialize radio toggle component.
 */
function initRadioToggle() {
    const toggleContainers = document.querySelectorAll(".ui-radio-toggle");

    if (toggleContainers.length <= 0) return;

    toggleContainers.forEach((toggleContainer) => {
        const toggleInputs = toggleContainer.querySelectorAll("input");
        const toggleDecoration =
            toggleContainer.querySelector(".button-decoration");

        setToggleDecorationPosition(toggleInputs, toggleDecoration);

        toggleInputs.forEach((input) => {
            input.addEventListener("input", () => {
                setToggleDecorationPosition(toggleInputs, toggleDecoration);
            });
        });
    });
}

/**
 * Enable toggle state for bootstrap popovers.
 */
function initBootstrapPopOvers() {
    const popoverTriggerList = [].slice.call(
        document.querySelectorAll("[data-bs-toggle=popover]")
    );

    popoverTriggerList.forEach((popoverTriggerEl) => {
        const popover = new Popover(popoverTriggerEl, {
            html: true,
            trigger: "manual",
            allowList: {
                ...DefaultAllowlist,
                "*": [
                    ...DefaultAllowlist["*"],
                    "data-copy",
                    "data-copy-message",
                ],
            },
        });

        popoverTriggerEl.addEventListener("click", () => {
            popover.toggle();
        });

        popoverTriggerEl.addEventListener("shown.bs.popover", () => {
            initCopyToClipboard();
        });

        return popover;
    });

    document.addEventListener("click", (e) => {
        if (
            !e.target.classList.contains("popover-body") &&
            !e.target.closest(".popover-body")
        ) {
            popoverTriggerList.forEach((popoverTriggerEl) => {
                const instance = Popover.getInstance(popoverTriggerEl);
                instance.hide();
            });
        }
    });
}

/**
 * Launch the animation for the waiting div
 */
function launchAnimationWaitingDiv() {
    // Hide and show part of the waiting div
    const waitingDiv = document.querySelector("#waiting-div");
    const loadingText = document.querySelector("#loading-text");

    if (waitingDiv && loadingText) {
        const downloadingText = document.querySelector("#downloading-text");

        waitingDiv.style.display = "block";
        loadingText.style.display = "block";

        // Launch the animation
        animateNumericProgression("papers", 0, 49, 2000);
        animateNumericProgression("participants", 0, 904342, 2000);
        animateNumericProgression("calculations", 0, 100, 2000);

        setTimeout(function () {
            loadingText.style.display = "none";
            downloadingText.style.display = "block";
        }, 2000);
    }
}

/**
 * Unset height of '.no-height' elements when iframe is detected
 */
function unsetHeightInIframe() {
    const noHeights = document.querySelectorAll(".no-height");

    if (window !== window.top) {
        noHeights.forEach((noHeight) => {
            noHeight.style.minHeight = "unset";
        });
    }
}

/**
 * Track tooltip positioning on scroll sidebar.
 */
function onScrollSidebar() {
    const aside = document.querySelector(".aside-navigator");
    const iconWrappers = document.querySelectorAll(".icon-wrapper");

    if (aside) {
        aside.addEventListener("scroll", () => {
            setAllTooltips(iconWrappers);
        });
    }
}

/**
 * Set tooltip position according to its icon offset.
 * @param {HTMLElement} iconWrapper
 */
function setTooltipPosition(iconWrapper) {
    const tooltip = iconWrapper.querySelector(".track-tooltip");

    if (tooltip) {
        const { top: iconTop, height: iconHeight } =
            iconWrapper.getBoundingClientRect();
        tooltip.style.top = `${
            iconTop - tooltip.clientHeight / 2 + iconHeight / 2
        }px`;
    }
}

/**
 * Set all tooltips position.
Wrappers
 */
function setAllTooltips(iconWrappers) {
    iconWrappers.forEach((iconWrapper) => {
        setTooltipPosition(iconWrapper);
    });
}

/*
 * Handle click element that has .copy-to-clipboard class.
 * @param {HTMLElement} copyElement
 */
function onClickCopyToClipboard(copyElement) {
    navigator.clipboard.writeText(copyElement.dataset.copy ?? "");
    const tooltip =
        copyElement?.parentElement?.querySelector(".icon-link-tooltip");

    if (tooltip) {
        tooltip.querySelectorAll(".copy-option-text").forEach((text) => {
            text.classList.toggle("d-none");
        });
    }
}

/**
 * Copy content to the clipboard.
 */
function initCopyToClipboard() {
    const copyElements = document.querySelectorAll(".copy-to-clipboard");

    copyElements.forEach((copyElement) => {
        copyElement.addEventListener("click", () =>
            onClickCopyToClipboard(copyElement)
        );
    });
}

/**
 * Reset tooltip text to default values.
 * @param {HTMLElement} tooltipText
 */
function resetTooltipText(tooltipText) {
    if (tooltipText.classList.contains("copied-text")) {
        tooltipText.classList.add("d-none");
    } else {
        tooltipText.classList.remove("d-none");
    }
}
/**
 * Reset tooltip text on close sharing options.
 */
function closeSharingOptions() {
    const input = document.getElementById("sharing-options-input");
    const texts = document.querySelectorAll(".copy-option-text");

    input?.addEventListener("input", () => {
        if (!input.checked) {
            texts.forEach(resetTooltipText);
        }
    });
}

/**
 * Initialise dot lens simulator functionalities.
 */
function initDotLens() {
    const zoom = document.getElementById("zoom-unzoom");
    const switchers = document.querySelectorAll(".dot-lens-switcher");

    if (zoom) {
        const zoomIn = zoom.querySelector(".dot-zoom-in");
        const zoomOut = zoom.querySelector(".dot-zoom-out");

        zoom.addEventListener("click", () => {
            const selected = document.querySelector(
                ".dot-lens-image-wrapper.show"
            );

            selected
                .querySelectorAll(".dot-image-section")
                .forEach((section) => {
                    section.classList.toggle("d-none");
                });

            zoom.querySelectorAll(".zoom-unzoom").forEach((magnifier) => {
                magnifier.classList.toggle("d-none");
            });
        });

        switchers.forEach((switcher) => {
            switcher.addEventListener("click", () => {
                zoomIn.classList.remove("d-none");
                zoomOut.classList.add("d-none");
                document
                    .querySelectorAll(".dot-image-section")
                    .forEach((divElem) => {
                        if (divElem.classList.contains("dot-lens-zoomed")) {
                            divElem.classList.add("d-none");
                        } else {
                            divElem.classList.remove("d-none");
                        }
                    });
            });
        });
    }
}

/**
 * There's a known bug in some browsers where transitions are triggered everytime the page load.
 */
function preloadTransitions() {
    const node = document.querySelector(".preload-transitions");
    if (node) {
        setTimeout(() => {
            node.classList.remove("preload-transitions");
        }, 500);
    }
}

function setDropdownInSafari() {
    if (isSafari()) {
        const parents = document.querySelectorAll(".dropdown-container");

        parents.forEach((parent) => {
            parent.classList.add("no-style-dropdown");
        });
    }
}

/**
 * Change select fields color on change event
 * since it's not possible to style option tags.
 */
function initStyleSelects() {
    const selects = document.querySelectorAll(".ui-kit.ui-select");

    if (!selects || selects.length === 0) return;

    selects.forEach((select) => {
        select.addEventListener("input", () => {
            applySelectStyle(select);
        });
    });
}

/**
 * Handles click input increment button.
 * @param {HTMLInputElement} inputIncrement
 */
function handleIncrementInput(inputIncrement) {
    const cardRange = inputIncrement.closest(".card-range");
    const input = inputIncrement.querySelector("input[type=number]");
    const rangeInput = cardRange.querySelector(".slider-range");
    const minus = inputIncrement.querySelector(".increment-minus");
    const plus = inputIncrement.querySelector(".increment-plus");
    const min = parseFloat(input.getAttribute("min"));
    const max = parseFloat(input.getAttribute("max"));
    const defaultValue = parseFloat(input.getAttribute("default"));
    const form = FormValidator.getInstance("submit-guide-form");
    const field = form.getField(input.dataset.validator);

    /**
     * Increment/decrement input value in 0.01 according to operator.
     * @param {"-"|"+"} operator
     */
    function onClickIncrementInput(operator) {
        const operatorValue = operator === "-" ? -0.01 : 0.01;
        const value = input.value
            ? parseFloat(input.value) + operatorValue
            : defaultValue;
        input.value = valueInRange(value, min, max).toFixed(2);
        onKeyupInputRange(0, cardRange, input, rangeInput);
        form.validateField(field);
    }

    minus.addEventListener("click", (event) => {
        event.preventDefault();
        if (document.activeElement === minus) {
            onClickIncrementInput("-");
            form.validateField(field);
        }
    });

    plus.addEventListener("click", (event) => {
        event.preventDefault();
        if (document.activeElement === plus) {
            onClickIncrementInput("+");
        }
    });
}

/**
 * Initialise increment input functionality.
 */
function initInputIncrement() {
    const inputIncrements = document.querySelectorAll(".input-increment");
    inputIncrements.forEach((inputIncrement) =>
        handleIncrementInput(inputIncrement)
    );
}

/**
 * Adjust the top offset of the sharing options container to ensure
 * that it is always entirely visible within the viewport.
 */
function setSharingOptionsTopOffset() {
    const sharingOptions = document.querySelector(".sharing-options");

    if (!sharingOptions) {
        return;
    }
    const { top } = sharingOptions.getBoundingClientRect();

    sharingOptions.style.marginTop = `calc(100vh - ${
        sharingOptions.offsetHeight + top
    }px - 20px)`;
}

function printPdf(pdfUrl) {
    const printWindow = window.open(pdfUrl, "_blank");

    printWindow.addEventListener("load", () => {
        setTimeout(() => {
            printWindow.print();
        }, 500);
    });
}

function initPrintPdf() {
    const urlDataSpan = document.getElementById("consent-form-url-data");

    if (!urlDataSpan) {
        return;
    }

    const consentFormUrl =
        window.location.origin + urlDataSpan.dataset.consentFormUrl;
    const consentFormButtons =
        document.getElementsByClassName("consent-form-btn");
    for (const button of consentFormButtons) {
        button.addEventListener("click", function () {
            printPdf(consentFormUrl);
        });
    }
}

/**
 * Set date input max attribute to today.
 */
function initMaxDateToday() {
    document.querySelectorAll(".max-date-today").forEach((input) => {
        input.max = formatISODate(new Date());
    });
}

/**
 * Expand target element.
 */
function initExpandCard() {
    const readMores = document.querySelectorAll(".read-more");

    readMores.forEach((readMore) => {
        readMore.addEventListener("click", () => {
            const { target: targetId } = readMore.dataset;
            const target = document.getElementById(targetId);
            target.classList.toggle("expand");
        });
    });
}

/**
 * Initialise progress circle functionality based on svg.
 */
function initProgressCircle() {
    const progressCircles = document.querySelectorAll(".progress-circle-svg");

    progressCircles.forEach((progressCircle) => {
        paintSvgCircle(progressCircle);
    });
}

/**
 * Calculates how much the circle should be painted according
 * to the percentage value and circle properties.
 * @param {SVGCircleElement} circle
 * @param {number} size
 * @param {number} percentage
 */
function computeProgress(circle, size, percentage) {
    const stroke = circle.getAttribute("stroke-width");
    const radius = size / 2 - parseInt(stroke) / 2;
    const transform = `rotate(-90 ${size / 2} ${size / 2})`;

    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - (dashArray * percentage) / 100;

    circle.setAttribute("r", radius);
    circle.setAttribute("transform", transform);
    circle.style.strokeDasharray = dashArray;
    circle.style.strokeDashoffset = dashOffset;
}

/**
 * Paints the svg circle according to the percentage and scale values.
 * @param {SVGElement} progressCircle
 */
function paintSvgCircle(progressCircle) {
    const background = progressCircle.querySelector(".circle-background");
    const progress = progressCircle.querySelector(".circle-progress");
    const size = progressCircle.getAttribute("width");
    const { percentage, range, min, max } = progressCircle.dataset;
    const value = valueInRange(percentage, min, max);
    const scalePercentage = computeScale(range, value, min, max);
    const circleFraction = 0.75;

    // The values 75 and 0.75 are used because the figure is a 3/4 circle.
    computeProgress(background, size, circleFraction * 100);
    computeProgress(progress, size, scalePercentage * circleFraction);
}

/**
 * Updates the value of an input element with the ID "browser-info"
 * to display the user's browser name and version.
 */
function updateBrowserInfoField() {
    const { name, version } = browser.getBrowser();

    const browserInfo = document.getElementById("browser-info");
    if (!browserInfo) {
        return;
    }
    browserInfo.value = `${name} : ${version}`;
}

/**
 * Closes all browser banners and optionally displays corresponding upgrade banners.
 *
 * This function selects all elements with IDs starting with "browser-banner-" and hides them.
 * If the user is not on a login or register page, it sets a cookie to remember the dismissal
 * of the browser banner for 7 days. Additionally, for each closed banner, it identifies the
 * mode (mobile or desktop) and displays the corresponding upgrade banner if available.
 *
 */
function closeBanner() {
    const browserBanners = document.querySelectorAll("[id^='browser-banner-']");

    if (browserBanners.length === 0) {
        return;
    }

    if (!isLoginOrRegisterPage()) {
        setCookie("browser_banner_dismissed", "true", 7);
    }
    browserBanners.forEach((banner) => {
        hideBanner(banner);

        // Get the mode (mobile or desktop)
        const mode = banner.id.replace("browser-banner-", "");

        // Find and show the corresponding upgrade banner
        const upgradeBanner = document.getElementById(`upgrade-banner-${mode}`);
        if (upgradeBanner) {
            showBanner(upgradeBanner);
        }
    });
}

function hideBanner(banner) {
    banner.classList.add("hide");
}

function showBanner(banner) {
    banner.classList.remove("hide");
}

/**
 * Initializes and manages the display of browser and upgrade banners based on browser
 * compatibility and user preferences.
 */
function initBrowserBanner() {
    const upgradeBanners = document.querySelectorAll("[id^='upgrade-banner-']");
    const browserBanners = document.querySelectorAll("[id^='browser-banner-']");

    const isDismissed = getCookie("browser_banner_dismissed");
    if (isDismissed === "true" && !isLoginOrRegisterPage()) {
        browserBanners.forEach((banner) => {
            hideBanner(banner);
        });

        if (upgradeBanners.length > 0) {
            upgradeBanners.forEach((banner) => {
                showBanner(banner);
            });
        }
        return;
    }

    const isValid = isValidBrowser();

    if (isValid) {
        // If browser is valid, hide browser banners and show upgrade banners normally
        browserBanners.forEach((banner) => {
            hideBanner(banner);
        });
        return;
    }

    const { name, version } = browser.getBrowser();
    const lowerName = name.toLowerCase();
    const isOutdated =
        MIN_SUPPORTED_BROWSER_VERSIONS[lowerName] &&
        parseFloat(version) < MIN_SUPPORTED_BROWSER_VERSIONS[lowerName];

    // Process each browser banner
    browserBanners.forEach((banner) => {
        const mode = banner.id.replace("browser-banner-", "");

        const outdatedMessage = document.getElementById(
            `browser-outdated-${mode}`
        );
        const unsupportedMessage = document.getElementById(
            `browser-unsupported-${mode}`
        );
        const mobileMessage = document.getElementById(`browser-mobile-${mode}`);

        // Find the corresponding upgrade banner
        const upgradeBanner = document.getElementById(`upgrade-banner-${mode}`);

        if (!outdatedMessage || !unsupportedMessage || !mobileMessage) {
            return;
        }

        // Hide both messages first to prevent conflicts
        outdatedMessage.style.display = "none";
        unsupportedMessage.style.display = "none";
        mobileMessage.style.display = "none";

        const deviceType = getBrowserDevice();

        if (deviceType === "mobile") {
            mobileMessage.style.display = "block";
        } else if (isOutdated) {
            outdatedMessage.style.display = "block";
        } else {
            unsupportedMessage.style.display = "block";
        }

        showBanner(banner);

        // Hide upgrade banner until browser banner is dismissed
        if (upgradeBanner) {
            hideBanner(upgradeBanner);
        }
    });

    const buttons = document.querySelectorAll("[id^='close-banner-btn-']");
    if (buttons.length > 0) {
        buttons.forEach((button) => {
            button.addEventListener("click", closeBanner);
        });
    }
}

export * from "./libs/error_validation_system";
export { getDate } from "./libs/date_of_birth";

window.addEventListener("load", () => {
    try {
        $("#tools-content").fadeTo(200, 1); // fadeIn when the tool is lauched
        initBootstrapPopOvers();
        removeVideoOnSafari();
        disableRequiredField();
        initShareLinks();
        initCardRange();
        initBrowserBanner();
        updateBrowserInfoField();
        initEventListener();
        setActiveNavBar();
        blockTabIndexes();
        initTelInputPlugin();
        focusAlert();
        initStyleSelects();
        initRadioToggle();
        initKrInput();
        initArcRangeInput();
        initVisionSimulator();
        unsetHeightInIframe();
        initLinkedToggles();
        initLinkedTabInputs();
        initUiKitTooltips();
        initFormValidator();
        postMessageSimulatorCtaButton();
        onScrollSidebar();
        initThemeToggle();
        // Uncomment when dark mode ready
        // initDynamicThemeChange();
        initCopyToClipboard();
        closeSharingOptions();
        initDotLens();
        preloadTransitions();
        setDropdownInSafari();
        initInputIncrement();
        setSharingOptionsTopOffset();
        initPrintPdf();
        initSidebarCollapseAppearance();
        initProgressCircle();
        initMaxDateToday();
        initExpandCard();
        initRepeater();
        initAutoCompleteSelect();
        treatDobInputs();
        onUnloadPageWithDob();
    } catch (error) {
        console.info(error);
    }
});
