import { z } from "zod";
import {
    addCustomVisitDates,
    areRadioInputsEmpty,
    areRegularInputsEmpty,
    getBaseRepeaterInputs,
    getRepeaterRows,
    getVisitDatesInputs,
    isBaseRowEmpty,
    isTimeDifferenceValid,
    isVisitRowEmpty,
} from "../../form_validator/extensions/collection/common/visit_repeater";
import {
    areAllValuesPresent,
    camelToKebabCase,
    empty,
    formatDateToYMD,
    isForm,
    isNumericString,
    kebabToCamelCase,
    parseFormat,
    stripTrailingNumbers,
} from "../../utils";

export const ScoreForms = Object.freeze({
    NEW: "submit-new-score-form",
    UPDATE: "submit-update-score-form",
    UPDATE_TREATED: "submit-use-score-form",
});

export function normalizeInputName(inputName) {
    return stripTrailingNumbers(
        kebabToCamelCase(inputName?.replace("_", "-")),
        /\d+$/
    );
}

export function getInputsData(inputs) {
    return [...inputs].reduce((prev, curr) => {
        const name = normalizeInputName(curr.name);
        prev[name] = curr.value;
        return prev;
    }, {});
}

export function getPartial(obj, keys = []) {
    if (!keys.length) {
        return obj;
    }

    return keys.reduce((partial, key) => {
        if (key in obj) {
            partial[key] = obj[key];
        }
        return partial;
    }, {});
}

export function getDemographicData(data) {
    return getPartial(data, [
        "name",
        "patientId",
        "ethnicity",
        "sex",
        "dobDate",
    ]);
}

export function getVisitsData(data) {
    return getPartial(data, [
        "visitDate",
        "sphereRight",
        "sphereLeft",
        "cylinderRight",
        "cylinderLeft",
        "axisRight",
        "axisLeft",
        "axialLengthRight",
        "axialLengthLeft",
    ]);
}

export function getTreatmentsData(data) {
    return getPartial(data, [
        "hasUsedTreatment",
        "selectTreatment",
        "selectEyes",
        "selectStatus",
        "startDate",
        "stopDate",
    ]);
}

export function getRowsData(repeaterId, normalizer) {
    const rows = getRepeaterRows(repeaterId, true);
    return rows.map((row) => {
        const inputs = [...row.querySelectorAll("input,select")];

        const _data = normalizer(
            inputs.reduce((prev, curr) => {
                if (curr.type === "radio") {
                    if (curr.checked) {
                        prev[normalizeInputName(curr.name)] = curr.value;
                    }
                } else {
                    prev[normalizeInputName(curr.name)] = curr.value;
                }
                return prev;
            }, {})
        );

        _data.id = row.id;

        return _data;
    });
}

export function getErrorMessages(issues) {
    return issues.reduce((prev, issue) => {
        prev[issue.path[0]] = issue.message;
        return prev;
    }, {});
}

export function handleSchemaValidation(schema, context, ignoreSchema = false) {
    const { data, source, inputs } = context;
    const name = source?.name;
    const keys = getSchemaKeys(schema);

    const schemaData = getPartial(context.data, keys);
    const validation = schema.safeParse(schemaData);
    const inputValidation = validateSingleField(schema, name, data[name]);
    const shouldValidateSingleField =
        !isForm(source) && inputValidation && !empty(name);

    if (validation.success || ignoreSchema) {
        hideSchemaMessages(keys);
        hideErrorClasses(keys, inputs);
        return true;
    }

    if (shouldValidateSingleField) {
        hideMessage(normalizeInputName(name));
        hideErrorClass(inputs, normalizeInputName(name));
    }

    if (!context.cleanUp) {
        showSchemaMessages(validation, keys);
        showErrorClasses(validation, inputs);
    }

    return false;
}

export function handleCustomValidations(
    effectRuleMap,
    effects,
    context,
    excludeRules = [],
    includeRules = []
) {
    let success = true;
    Object.entries(effectRuleMap).forEach(([effect, rules]) => {
        let _rules = rules;

        if (effect in effects) {
            const effectFunction = effects[effect];

            if (excludeRules.length) {
                _rules = rules.filter((rule) => !excludeRules.includes(rule));
            } else if (includeRules.length) {
                _rules = rules.filter((rule) => includeRules.includes(rule));
            }

            const isActive = _rules.some((rule) => !!rule(context));

            effectFunction(isActive, context);

            if (isActive) {
                success = false;
            }
        }
    });
    return success;
}

export function clearCustomValidations(effectRuleMap, effects, context) {
    Object.entries(effectRuleMap).forEach(([effect]) => {
        if (effect in effects) {
            const effectFunction = effects[effect];
            effectFunction(false, context);
        }
    });
}

export function clearSchemaValidations(schema, context) {
    const { inputs } = context;
    const keys = getSchemaKeys(schema);

    hideSchemaMessages(keys);
    hideErrorClasses(keys, inputs);
}

export function isVisitsInputsEmpty() {
    return isBaseRowEmpty("visit-input-repeater")[0];
}

export function isTreatmentsInputsEmpty() {
    return (
        isBaseRowEmpty("treatment-input-repeater")[0] ||
        isTreatmentsInputsPartiallyEmpty()
    );
}

export function isTreatmentsInputsPartiallyEmpty() {
    const allInputs = getBaseRepeaterInputs("treatment-input-repeater");

    const emptyRegular = allInputs.some((inputs) =>
        areRegularInputsEmpty(inputs)
    );
    const emptyRadio = allInputs.some((inputs) => areRadioInputsEmpty(inputs));

    return emptyRegular && !emptyRadio;
}

export function getSchemaKeys(schema) {
    return Object.keys(schema.shape);
}

export function showMessage(name, messages) {
    const error = document.querySelector(`[data-validator-error=${name}`);

    if (error) {
        error.classList.remove("invisible");
        error.innerText = messages[name];
    }
}
export function hideMessage(name) {
    const error = document.querySelector(`[data-validator-error=${name}`);

    if (error) {
        error.classList.add("invisible");
        error.innerText = ".";
    }
}

export function showSchemaMessages(validation, keys) {
    const issues = validation?.error?.issues ?? [];
    const messages = getErrorMessages(issues);
    const issuesKeys = issues.map((issue) => issue.path[0]);
    const succeded = keys.filter((key) => !issuesKeys.includes(key));

    issues.forEach((issue) => {
        const [path] = issue.path;
        showMessage(path, messages);
    });

    succeded.forEach((key) => {
        hideMessage(key);
    });
}

export function getInputErrorParams(input) {
    const errorSelector = input.dataset.errorSelector
        ? input.closest(input.dataset.errorSelector)
        : input;
    const { errorClass } = input.dataset;

    return { errorSelector, errorClass };
}

export function hideSchemaMessages(names) {
    names.forEach((name) => {
        hideMessage(name);
    });
}

export function showErrorClasses(validation, inputs) {
    const issues = validation?.error?.issues ?? [];

    issues.forEach((issue) => {
        const [path] = issue.path;
        showErrorClass(inputs, path);
    });
}

export function hideErrorClasses(names, inputs) {
    names.forEach((name) => {
        hideErrorClass(inputs, name);
    });
}

export function showErrorClass(inputs, name) {
    const input = inputs.find(
        (input) => normalizeInputName(input.name) === name
    );

    if (input) {
        const { errorSelector, errorClass } = getInputErrorParams(input);
        errorSelector?.classList?.add(errorClass);
    }
}

export function hideErrorClass(inputs, name) {
    const input = inputs.find(
        (input) => normalizeInputName(input.name) === name
    );

    if (input) {
        const { errorSelector, errorClass } = getInputErrorParams(input);
        errorSelector?.classList?.remove(errorClass);
    }
}

function formatVisits(list, format) {
    return [...list].map((item) =>
        item.value !== "-" && item.value !== ""
            ? parseFormat(format, item.value, true)?.[0]
            : undefined
    );
}

export function generateVisitsFieldMap(rows) {
    return rows.reduce((prev, curr) => {
        const date = curr.querySelector("input[name=visit-date]");
        const spheres = curr.querySelectorAll("[name^=sphere]");
        const axials = curr.querySelectorAll("[name^=axial-length]");

        prev[date.value] = {
            sphere: formatVisits(spheres, "0.00D"),
            axial: formatVisits(axials, "0.00mm"),
        };

        return prev;
    }, {});
}

export function hasIncorrectSphereOrAxial(fieldMap, minRows) {
    const res = Object.values(fieldMap)
        .slice(0, minRows)
        .every((field) => {
            const [, { sphere, axial }] = field;

            const hasValidSpheres = areAllValuesPresent(sphere);
            const hasValidAxials = areAllValuesPresent(axial);

            return hasValidSpheres || hasValidAxials;
        });

    return !res;
}

export function validateSingleField(schema, name, value) {
    const inputValidation = schema
        .pick({ [name]: true })
        .safeParse({ [name]: value });
    return inputValidation?.success;
}

export function normalize(value, format = undefined) {
    if (empty(value)) {
        return undefined;
    }

    switch (format) {
        case "number":
            return Number(value);
        case "date":
            return new Date(value);
        default:
            return value;
    }
}

export function extractValue(value) {
    const numberRegex = /-?\d+(\.\d+)?/;
    const match = value?.match(numberRegex);

    if (match) {
        return match[0];
    }
}

export function getValidationMessages(errorMessageElements) {
    return [...errorMessageElements].reduce((prev, error) => {
        const messages = JSON.parse(error.dataset.vMessages);
        prev[error.dataset.validatorError] = messages;
        return prev;
    }, {});
}

/**
 * Get whichever score form is available.
 * @returns {HTMLFormElement}
 */
export function getScoreForm() {
    const form =
        document.getElementById(ScoreForms.NEW) ||
        document.getElementById(ScoreForms.UPDATE_TREATED) ||
        document.getElementById(ScoreForms.UPDATE);

    return form;
}

export function getFirstVisitDate() {
    const visitDate =
        document.getElementById("visit-date-1") ||
        document.getElementById("visit-date");

    return visitDate;
}

export function normalizeDemographicData(data) {
    return {
        name: normalize(data.name),
        patientId: normalize(data.patientId),
        ethnicity: normalize(data.ethnicity),
        sex: normalize(data.sex),
        dobDate: normalize(data.dobDate, "date"),
    };
}

export function normalizeVisitsData(data) {
    return {
        visitDate: normalize(data.visitDate, "date"),
        sphereRight: normalize(extractValue(data.sphereRight), "number"),
        cylinderRight: normalize(extractValue(data.cylinderRight), "number"),
        axisRight: normalize(extractValue(data.axisRight), "number"),
        axialLengthRight: normalize(
            extractValue(data.axialLengthRight),
            "number"
        ),
        sphereLeft: normalize(extractValue(data.sphereLeft), "number"),
        cylinderLeft: normalize(extractValue(data.cylinderLeft), "number"),
        axisLeft: normalize(extractValue(data.axisLeft), "number"),
        axialLengthLeft: normalize(
            extractValue(data.axialLengthLeft),
            "number"
        ),
    };
}

export function normalizeTreatmentsDataRow(data) {
    return {
        selectTreatment: normalize(data.selectTreatment),
        startDate: normalize(data.startDate, "date"),
        selectStatus: normalize(data.selectStatus),
        stopDate: normalize(data.stopDate, "date"),
        selectEyes: normalize(data.selectEyes),
    };
}

export function normalizeTreatmentsData(data) {
    return {
        hasUsedTreatment: normalize(data.hasUsedTreatment),
        ...normalizeTreatmentsDataRow(data),
    };
}

export function normalizeScoreData(data) {
    return {
        ...normalizeDemographicData(data),
        ...normalizeVisitsData(data),
        ...normalizeTreatmentsData(data),
    };
}

export function getMinVisits() {
    const minVisitsError = document.getElementById("visits-errors");
    const min = minVisitsError?.dataset?.min;
    return min ? Number(min) : 0;
}

export function messageBuilder(error) {
    const errorMessages = JSON.parse(error.dataset.messages);
    const activeMessages = error.textContent
        .trim()
        .split(", ")
        .filter((message) => message !== "" && message !== ".");

    const get = (name) => {
        return errorMessages[name];
    };

    const add = (name) => {
        if (!activeMessages.includes(get(name))) {
            activeMessages.push(get(name));
            build();
        }
    };

    const remove = (name) => {
        if (activeMessages.includes(get(name))) {
            const index = activeMessages.indexOf(get(name));
            activeMessages.splice(index, 1);
            build();
        }
    };

    const build = () => {
        if (!hasErrors()) {
            error.textContent = ".";
        } else {
            error.textContent = activeMessages.join(", ");
        }
    };

    const hasErrors = () => {
        return activeMessages.length > 0;
    };

    return {
        add,
        remove,
        hasErrors,
    };
}

/**
 *
 * @param {HTMLElement} container
 */
export function blinkBorder(container) {
    container.classList.remove("blink-border");

    setTimeout(() => {
        container.classList.add("blink-border");
    }, 10);
}

/**
 * Updates the select start date options.
 */
export function updateSelectStartDateOptions() {
    const visitItems = getRepeaterRows("visit-input-repeater");
    const selectElement = document.getElementById("select-start-date");
    const visitDateInputs = getVisitDatesInputs(visitItems);

    addCustomVisitDates(selectElement, visitDateInputs);
}

/**
 * Checks if form is a treated update score form.
 * @returns {boolean}
 */
export function isTreatedUpdateForm() {
    return getScoreForm()?.id === ScoreForms.UPDATE_TREATED;
}

/**
 * Get indexed id based on the provided `id` and `refId`.
 * @param {string} id
 * @param {string} refId
 * @returns {string}
 */
export function getIndexedId(id, refId) {
    const rowIdList = id.split("-");
    const last = rowIdList[rowIdList.length - 1];
    return isNumericString(last) ? `${refId}-${last}` : refId;
}

/**
 * Validate list of rows schema.
 * @param {object[]} rows
 * @param {z.ZodObject} schema
 * @param {object} context
 * @returns {boolean}
 */
export function validateSchemaByRows(rows, schema, context) {
    const { cleanUp } = context;
    let isValid = true;

    rows.forEach((row) => {
        const rowElement = document.getElementById(row.id);

        if (isVisitRowEmpty(rowElement)) {
            return;
        }

        Object.keys(row).forEach((key) => {
            const error = rowElement.querySelector(
                `[data-validator-error=${key}`
            );
            const input = rowElement.querySelector(
                `[name=${camelToKebabCase(key)}]`
            );

            if (!input) {
                return;
            }

            const { errorSelector, errorClass } = getInputErrorParams(input);

            if (key in schema.shape) {
                const validation = schema.pick({ [key]: true }).safeParse(row);

                if (validation.success) {
                    hideError(error, errorSelector, errorClass);
                } else {
                    isValid = false;
                    if (!cleanUp) {
                        const messages = getErrorMessages(
                            validation.error.issues
                        );
                        showError(
                            error,
                            errorSelector,
                            errorClass,
                            messages[key]
                        );
                    }
                }
            }
        });
    });

    return isValid;
}

/**
 * Show error message.
 * @param {HTMLElement} error
 * @param {HTMLElement} errorSelector
 * @param {string} errorClass
 * @param {string} message
 */
export function showError(error, errorSelector, errorClass, message) {
    if (!error) {
        return;
    }
    errorSelector.classList.add(errorClass);
    error.classList.remove("invisible");
    error.innerText = message;
}

/**
 * Hide error message.
 * @param {HTMLElement} error
 * @param {HTMLElement} errorSelector
 * @param {string} errorClass
 */
export function hideError(error, errorSelector, errorClass) {
    if (!error) {
        return;
    }
    errorSelector.classList.remove(errorClass);
    error.classList.add("invisible");
    error.innerText = ".";
}

/**
 * Check if data object of a row is empty;
 * @param {object} rowData
 * @param {string[]} excludes
 */
export function isRowDataEmpty(rowData, excludes = []) {
    return Object.entries(rowData)
        .filter(([key]) => !excludes.includes(key))
        .every(([_, value]) => empty(value));
}

/**
 * Prepopulate visit date input value.
 * @param {HTMLInputElement} input
 */
export function prePopulateVisitDate(input) {
    const today = formatDateToYMD(new Date());
    input.value = today;
    const isValid = isTimeDifferenceValid("visit-input-repeater", true);
    input.value = isValid ? today : "";
}
